import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Form, Button, Spin, Modal } from "antd";
import PopupIndicatorSave from "./PopupIndicatorSave";
import { exportToCSV } from "../services/export.service";

const PopupIndicatorActions = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [user, setUser] = useState(null);

  const { id } = useParams();

  const userId = id;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const { recordId, indicators, trackerName, record } = props;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const exportAllToCSV = () => {
    const headers = Object.keys(indicators);
    const values = headers.map((key) => indicators[key]);
    const filename = `${record.title}_indicators_all.csv`;
    exportToCSV(headers, [values], filename);
    setVisible(false);
  };

  const exportRestrictedToCSV = () => {
    const headers = JSON.parse(localStorage.getItem("indicators"));
    const values = headers.map((key) => indicators[key]);
    const filename = `${record.title}_indicators.csv`;
    exportToCSV(headers, [values], filename);
    setVisible(false);
  };

  const downloadAllIndicators = (form_data) => {
    exportAllToCSV();
  };

  const exportRawData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/sessions/raw/`, {
        headers: { "x-access-token": accessToken },
        params: {
          id: record.sessionId,
        },
      })
      .then((response) => {
        setLoading(true);
        const headers = response.data.shift();
        const lines = response.data;
        exportToCSV(headers, lines, `${record.title}_raw_data.csv`);
        setLoading(false);
        setVisible(false);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t, isMobile } = props;

  return (
    <>
      <Button
        type="primary"
        style={{
          marginBottom: "7%",
        }}
        className="session-form-button"
        onClick={() => {
          showModal();
        }}
      >
        {t("Options")}
      </Button>

      <Modal
        className="athlete-modal"
        visible={visible}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
        title={t("Actions on data")}
        footer={null}
      >
        {loading ? (
          <>
            <Spin tip={t("Loading ...")}></Spin>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Form
              name="session_form"
              style={{ flex: "1" }}
              initialValues={{ remember: true }}
              onFinish={exportRestrictedToCSV}
              onClick={(e) => e.stopPropagation()}
              onFinishFailed={onSubmitFailed}
              className="session_form"
              layout="vertical"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
                style={{
                  width: "85%",
                  backgroundColor: "transparent",
                  color: "#d5bb64",
                  border: "1px solid #d5bb64",
                }}
              >
                {t("Download current table")}
              </Button>
            </Form>
            <Form
              style={{ flex: "1" }}
              name="session_form"
              initialValues={{ remember: true }}
              onFinish={downloadAllIndicators}
              onClick={(e) => e.stopPropagation()}
              onFinishFailed={onSubmitFailed}
              className="session_form"
              layout="vertical"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
                style={{
                  width: "85%",
                  backgroundColor: "transparent",
                  color: "#d5bb64",
                  border: "1px solid #d5bb64",
                }}
              >
                {t("Download all indicators")}
              </Button>
            </Form>
            {user &&
              user.roles.filter((obj) => obj.name === "athlete").length ===
                0 && (
                <Form
                  style={{ flex: "1" }}
                  name="session_form"
                  initialValues={{ remember: true }}
                  onFinish={exportRawData}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form"
                  layout="vertical"
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                    style={{
                      width: "85%",
                      backgroundColor: "transparent",
                      color: "#d5bb64",
                      border: "1px solid #d5bb64",
                    }}
                  >
                    {t("Download decoded data")}
                  </Button>
                </Form>
              )}
            <Form
              style={{ flex: "1" }}
              name="session_form"
              initialValues={{ remember: true }}
              onClick={(e) => e.stopPropagation()}
              onFinishFailed={onSubmitFailed}
              className="session_form"
              layout="vertical"
            >
              <PopupIndicatorSave recordId={recordId} />
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(PopupIndicatorActions);
