import React, { useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import {
  Form,
  Button,
  Spin,
  Modal,
} from "antd";
import { useParams } from "react-router-dom";

const PopupIndicatorSave = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { id } = useParams();

  const userId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));
  let indicsArray = JSON.parse(localStorage.getItem("indicators"));

  const { recordId } = props;

  const onSubmit0 = (form_data) => {
    setLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}api/v1/record/indicators/${userId}`,
        {
          indicatorsColumns: indicsArray,
          allRecords: true,
        },
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((res) => {
        console.log("Settings File uploaded");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  const onSubmit1 = (form_data) => {
    setLoading(true);
    console.log("SUBMIT");
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}api/v1/record/indicators/${recordId}`,
        {
          indicatorsColumns: indicsArray,
          onlyOne: true,
        },
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((response) => {
        console.log("Settings File uploaded");
        setLoading(false);
        window.location.reload();
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t, isMobile } = props;

  return (
    <>
      {!isMobile ? (
        <Button
          type="primary"
          style={{
            marginBottom: "2%",
            width: "85%",
            backgroundColor: "transparent",
            color: "#d5bb64",
            border: "1px solid #d5bb64",
          }}
          className="session-form-button"
          onClick={() => {
            showModal();
          }}
        >
          {t("Save template")}
        </Button>
      ) : (
        <p
          onClick={() => {
            showModal();
          }}
        >
          {t("Save template")}
        </p>
      )}

      <Modal
        className="athlete-modal"
        visible={visible}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
        title={t("Save indicators list")}
        footer={null}
      >
        {loading ? (
          <>
            <Spin tip={t("Loading ...")}></Spin>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Form
              name="session_form"
              style={{ flex: "1" }}
              initialValues={{ remember: true }}
              onFinish={onSubmit1}
              onClick={(e) => e.stopPropagation()}
              onFinishFailed={onSubmitFailed}
              className="session_form"
              layout="vertical"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
                style={{
                  width: "85%",
                  backgroundColor: "transparent",
                  color: "#d5bb64",
                  border: "1px solid #d5bb64",
                }}
              >
                {t("Save for this session")}
              </Button>
            </Form>
            <Form
              style={{ flex: "1" }}
              name="session_form"
              initialValues={{ remember: true }}
              onFinish={onSubmit0}
              onClick={(e) => e.stopPropagation()}
              onFinishFailed={onSubmitFailed}
              className="session_form"
              layout="vertical"
            >
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
                style={{
                  width: "85%",
                  backgroundColor: "transparent",
                  color: "#d5bb64",
                  border: "1px solid #d5bb64",
                }}
              >
                {t("Save for all sessions")}
              </Button>
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default withTranslation()(PopupIndicatorSave);
