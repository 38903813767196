import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import i18n from "../i18n";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { Row, Col, Radio, Form, Input, Button, Select, Collapse } from "antd";
import "antd/dist/antd.css";
import "./Settings.css";

const SettingsUnits = (props) => {
  const [form] = Form.useForm();
  const [speedUnit, setSpeedUnit] = useState(
    localStorage.getItem("speedUnitFactor")
  );
  const [distanceUnit, setDistanceUnit] = useState(
    localStorage.getItem("distanceUnitFactor")
  );
  const [language, setLanguage] = useState(localStorage.getItem("keyLang"));
  const [loading, setLoading] = useState(true);
  const [trackers, setTrackers] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [user, setUser] = useState(null);

  const { id } = useParams();

  const coachId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const headers = {
    "x-access-token": accessToken,
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${coachId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((res) => {
        setUser(res.data);
        Promise.all([
          axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${coachId}`,
              {
                headers: { "x-access-token": accessToken },
              }
            )
            .then((response) => {
              setLoading(false);
              console.log(response.data);
              setCurrentData(response.data);
            }),
          axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/coach/${coachId}`,
              {
                headers: { "x-access-token": accessToken },
              }
            )
            .then((response) => {
              console.log(response.data);
              setTrackers(response.data);
            }),
        ]).then(() => setLoading(false));
      });
  }, []);

  const { Panel } = Collapse;

  const Option = Select.Option;

  console.log(currentData);

  const onSubmit0 = (form_data) => {
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            thresoldAcc: parseFloat(form_data.thresoldAcc),
            thresoldAccDist: parseFloat(form_data.thresoldAccDist),
            thresoldDecDist: parseFloat(form_data.thresoldDecDist),
            mminGreat: parseFloat(form_data.mminGreat),
            thresoldAerobie: parseFloat(form_data.thresoldAerobie),
            thresoldAnaerobie: parseFloat(form_data.thresoldAnaerobie),
            thres: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit1 = (form_data) => {
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesAccMin1: parseFloat(form_data.zonesAccMin1),
            zonesAccMin2: parseFloat(form_data.zonesAccMin2),
            zonesAccMin3: parseFloat(form_data.zonesAccMin3),
            zonesAccMin4: parseFloat(form_data.zonesAccMin4),
            zonesAccMin5: parseFloat(form_data.zonesAccMin5),
            zonesAccMax1: parseFloat(form_data.zonesAccMax1),
            zonesAccMax2: parseFloat(form_data.zonesAccMax2),
            zonesAccMax3: parseFloat(form_data.zonesAccMax3),
            zonesAccMax4: parseFloat(form_data.zonesAccMax4),
            zonesAccMax5: parseFloat(form_data.zonesAccMax5),
            acc: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit2 = (form_data) => {
    setLoading(true);
    console.log(parseFloat(form_data.zonesSpeedMin1));
    console.log(parseFloat(form_data.zonesSpeedMin2));
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesSpeedMin1: parseFloat(form_data.zonesSpeedMin1),
            zonesSpeedMin2: parseFloat(form_data.zonesSpeedMin2),
            zonesSpeedMin3: parseFloat(form_data.zonesSpeedMin3),
            zonesSpeedMin4: parseFloat(form_data.zonesSpeedMin4),
            zonesSpeedMin5: parseFloat(form_data.zonesSpeedMin5),
            zonesSpeedMax1: parseFloat(form_data.zonesSpeedMax1),
            zonesSpeedMax2: parseFloat(form_data.zonesSpeedMax2),
            zonesSpeedMax3: parseFloat(form_data.zonesSpeedMax3),
            zonesSpeedMax4: parseFloat(form_data.zonesSpeedMax4),
            zonesSpeedMax5: parseFloat(form_data.zonesSpeedMax5),
            speed: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit3 = (form_data) => {
    console.log(parseFloat(form_data.zonesAmaxMin1));
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesAmaxMin1: parseFloat(form_data.zonesAmaxMin1),
            zonesAmaxMin2: parseFloat(form_data.zonesAmaxMin2),
            zonesAmaxMin3: parseFloat(form_data.zonesAmaxMin3),
            zonesAmaxMin4: parseFloat(form_data.zonesAmaxMin4),
            zonesAmaxMin5: parseFloat(form_data.zonesAmaxMin5),
            zonesAmaxMax1: parseFloat(form_data.zonesAmaxMax1),
            zonesAmaxMax2: parseFloat(form_data.zonesAmaxMax2),
            zonesAmaxMax3: parseFloat(form_data.zonesAmaxMax3),
            zonesAmaxMax4: parseFloat(form_data.zonesAmaxMax4),
            zonesAmaxMax5: parseFloat(form_data.zonesAmaxMax5),
            amax: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit4 = (form_data) => {
    console.log(parseFloat(form_data.zonesVMAMin1));
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesVMAMin1: parseFloat(form_data.zonesVMAMin1),
            zonesVMAMin2: parseFloat(form_data.zonesVMAMin2),
            zonesVMAMin3: parseFloat(form_data.zonesVMAMin3),
            zonesVMAMin4: parseFloat(form_data.zonesVMAMin4),
            zonesVMAMin5: parseFloat(form_data.zonesVMAMin5),
            zonesVMAMax1: parseFloat(form_data.zonesVMAMax1),
            zonesVMAMax2: parseFloat(form_data.zonesVMAMax2),
            zonesVMAMax3: parseFloat(form_data.zonesVMAMax3),
            zonesVMAMax4: parseFloat(form_data.zonesVMAMax4),
            zonesVMAMax5: parseFloat(form_data.zonesVMAMax5),
            vma: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit5 = (form_data) => {
    setLoading(true);
    console.log(parseFloat(form_data.zonesDistVmaxMin1));
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesDistVmaxMin1: parseFloat(form_data.zonesDistVmaxMin1),
            zonesDistVmaxMin2: parseFloat(form_data.zonesDistVmaxMin2),
            zonesDistVmaxMin3: parseFloat(form_data.zonesDistVmaxMin3),
            zonesDistVmaxMin4: parseFloat(form_data.zonesDistVmaxMin4),
            zonesDistVmaxMin5: parseFloat(form_data.zonesDistVmaxMin5),
            zonesDistVmaxMax1: parseFloat(form_data.zonesDistVmaxMax1),
            zonesDistVmaxMax2: parseFloat(form_data.zonesDistVmaxMax2),
            zonesDistVmaxMax3: parseFloat(form_data.zonesDistVmaxMax3),
            zonesDistVmaxMax4: parseFloat(form_data.zonesDistVmaxMax4),
            zonesDistVmaxMax5: parseFloat(form_data.zonesDistVmaxMax5),
            vmax: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit6 = (form_data) => {
    console.log(parseFloat(form_data.zonesMminMin1));
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesMminMin1: parseFloat(form_data.zonesMminMin1),
            zonesMminMin2: parseFloat(form_data.zonesMminMin2),
            zonesMminMin3: parseFloat(form_data.zonesMminMin3),
            zonesMminMin4: parseFloat(form_data.zonesMminMin4),
            zonesMminMin5: parseFloat(form_data.zonesMminMin5),
            zonesMminMax1: parseFloat(form_data.zonesMminMax1),
            zonesMminMax2: parseFloat(form_data.zonesMminMax2),
            zonesMminMax3: parseFloat(form_data.zonesMminMax3),
            zonesMminMax4: parseFloat(form_data.zonesMminMax4),
            zonesMminMax5: parseFloat(form_data.zonesMminMax5),
            mmin: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit7 = (form_data) => {
    console.log(parseFloat(form_data.zonesPowerMin1));
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            zonesPowerMin1: parseFloat(form_data.zonesPowerMin1),
            zonesPowerMin2: parseFloat(form_data.zonesPowerMin2),
            zonesPowerMin3: parseFloat(form_data.zonesPowerMin3),
            zonesPowerMin4: parseFloat(form_data.zonesPowerMin4),
            zonesPowerMin5: parseFloat(form_data.zonesPowerMin5),
            zonesPowerMax1: parseFloat(form_data.zonesPowerMax1),
            zonesPowerMax2: parseFloat(form_data.zonesPowerMax2),
            zonesPowerMax3: parseFloat(form_data.zonesPowerMax3),
            zonesPowerMax4: parseFloat(form_data.zonesPowerMax4),
            zonesPowerMax5: parseFloat(form_data.zonesPowerMax5),
            power: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit8 = (form_data) => {
    console.log(parseFloat(form_data.thresoldSprint));
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            thresoldSprint: parseFloat(form_data.thresoldSprint),
            durSprint: parseFloat(form_data.durSprint),
            sprint: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit9 = (form_data) => {
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            angleChange: parseFloat(form_data.angleChange),
            thresoldSpeed: parseFloat(form_data.thresoldSpeed),
            angle: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit10 = (form_data) => {
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            minDuration: parseFloat(form_data.minDuration),
            dur: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
          window.location.reload();
        });
      }),
    ]).then(() => setLoading(false));
  };
  const onSubmit11 = () => {
    setLoading(true);
    console.log("SUBMIT");
    Promise.all([
      trackers.map(async (tracker) => {
        axios({
          method: "put",
          url: `${process.env.REACT_APP_SERVER_URL}api/v1/record/file/${tracker.id}`,

          headers: { "x-access-token": accessToken },

          data: {
            restore: true,
          },
        }).then((response) => {
          console.log("Settings File uploaded");
        });
      }),
    ]).then(() => {
      setLoading(false);
      window.location.reload();
    });
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const changeLanguage = (event) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`,

      headers: { "x-access-token": accessToken },

      data: {
        language: event,
      },
    })
      .then((response) => {
        setLanguage(response.data.language.toString());
        localStorage.setItem("keyLang", response.data.language.toString());
        i18n.changeLanguage(response.data.language.toString());
      })
      .catch((error) => console.log(error));
  };

  const handleChangeSpeed = (event) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`,
      headers: { "x-access-token": accessToken },
      data: {
        speedUnit: event.target.value,
      },
    })
      .then((response) => {
        setSpeedUnit(response.data.speedUnit.toString());
        localStorage.setItem(
          "speedUnitFactor",
          response.data.speedUnit.toString()
        );
      })
      .catch((error) => console.log(error));
  };

  const handleChangeDistance = (event) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`,
      headers: { "x-access-token": accessToken },
      data: {
        distanceUnit: event.target.value,
      },
    })
      .then((response) => {
        setDistanceUnit(response.data.distanceUnit.toString());
        localStorage.setItem(
          "distanceUnitFactor",
          response.data.distanceUnit.toString()
        );
      })
      .catch((error) => console.log(error));
  };

  const { t } = props;

  return (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("User Settings")}</h1>
      <div className="mainContainer">
        <div
          style={{ textAlign: "left", marginBottom: "2%", paddingTop: "2%" }}
        >
          <Select
            onChange={(e) => changeLanguage(e)}
            value={language}
            className="selectLanguage"
          >
            <Option value="fr">{t("Français")}</Option>
            <Option value="en">{t("English")}</Option>
          </Select>
        </div>
        <Row className="settingsContainer" align="middle">
          <Col
            key={10}
            type="flex"
            align="left"
            className="settingsContent"
            span={8}
          >
            {t("Speed Unit")}
          </Col>
          <Radio.Group
            className="settingsContent"
            onChange={(e) => handleChangeSpeed(e)}
            value={speedUnit}
          >
            <Radio value="mph">mph</Radio>
            <Radio value="m/s">m/s</Radio>
            <Radio value="km/h">km/h</Radio>
          </Radio.Group>
        </Row>
        <Row className="settingsContainer" style={{ marginBottom: "2%" }}>
          <Col
            key={1}
            span={8}
            type="flex"
            align="left"
            className="settingsContent"
          >
            {t("Distance Unit")}
          </Col>
          <Radio.Group
            onChange={(e) => handleChangeDistance(e)}
            value={distanceUnit}
            className="settingsContent"
          >
            <Radio value="miles">{t("Miles")}</Radio>

            <Radio value="m">{t("Meters")}</Radio>

            <Radio value="yards">{t("Yards")}</Radio>

            <Radio value="feets">{t("Feets")}</Radio>
          </Radio.Group>
        </Row>
        {user &&
          user.roles.filter((obj) => obj.name === "athlete").length === 0 &&
          currentData.length && (
            <Collapse defaultActiveKey={["0"]}>
              <Panel header={t("VMA and acceleration")} key="0">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit0}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="thresoldAcc"
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                      label={t("Acceleration thresold")}
                      className="session_ifichier_par_menput_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["seuil_acc"]}
                    >
                      <Input name="thresoldAcc" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      label={t("Acceleration distance thresold")}
                      name="thresoldAccDist"
                      className="session_input_text"
                      style={{ flex: "1" }}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                      initialValue={
                        currentData[0]["seuil_acceleration_distance"]
                      }
                    >
                      <Input name="thresoldAccDist" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="thresoldDecDist"
                      label={t("Deceleration distance thresold")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={
                        currentData[0]["seuil_deceleration_distance"]
                      }
                      rules={[
                        {
                          pattern: new RegExp(/^-?\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="thresoldDecDist" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="mminGreat"
                      label={t("M/min greater than (km/h)")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["seuil_mmin"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="mminGreat" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="thresoldAerobie"
                      label={t("Aerobie thresold")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["seuil_aerobie_pourc_vma"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="thresoldAerobie" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="thresoldAnaerobie"
                      label={t("Anaerobie thresold")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["seuil_anaerobie_pourc_vma"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="thresoldAnaerobie" disabled={loading} />
                    </Form.Item>
                  </div>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("Acceleration zones (m/s2)")} key="1">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  onVal
                  form={form}
                  onFinish={onSubmit1}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAccMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            console.log(form.getFieldValue("zonesAccMax1"));
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAccMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAccMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAccMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAccMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAccMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAccMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAccMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAccMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAccMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAccMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAccMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAccMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAccMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAccMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAccMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAccMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAccMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAccMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_acc_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAccMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAccMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("Speed zones (km/h)")} key="2">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onChange={() => form.validateFields()}
                  onFinish={onSubmit2}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesSpeedMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            console.log(
                              "ZONE : ",
                              zone,
                              " MAX: ",
                              form.getFieldValue("zonesSpeedMax1")
                            );
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesSpeedMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesSpeedMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesSpeedMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesSpeedMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesSpeedMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesSpeedMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesSpeedMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesSpeedMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesSpeedMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesSpeedMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesSpeedMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesSpeedMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesSpeedMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesSpeedMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesSpeedMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesSpeedMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),
                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesSpeedMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesSpeedMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vit_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),
                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesSpeedMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesSpeedMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel
                header={t("Acceleration zones regarding amax percentage 0-100")}
                key="31"
              >
                <Form
                  name="session_form"
                  onChange={() => form.validateFields()}
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit3}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAmaxMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAmaxMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAmaxMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAmaxMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAmaxMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAmaxMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAmaxMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAmaxMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAmaxMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAmaxMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAmaxMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAmaxMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAmaxMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAmaxMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAmaxMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAmaxMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesAmaxMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesAmaxMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesAmaxMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_pourc_amax_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesAmaxMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesAmaxMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("VMA zones in percent 0-100")} key="4">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit4}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesVMAMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesVMAMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesVMAMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesVMAMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesVMAMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesVMAMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and less than zone max 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesVMAMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesVMAMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesVMAMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesVMAMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesVMAMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesVMAMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesVMAMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesVMAMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesVMAMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesVMAMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesVMAMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesVMAMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesVMAMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vma_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesVMAMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be greater than 0 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesVMAMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel
                header={t("Distance zones regarding VMAX percentage 0-100")}
                key="5"
              >
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit5}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesDistVmaxMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMax1")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesDistVmaxMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMin1")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesDistVmaxMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMax2")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesDistVmaxMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMin2")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesDistVmaxMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMax3")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesDistVmaxMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMin3")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesDistVmaxMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMax4")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesDistVmaxMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMin4")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesDistVmaxMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone <
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMax5")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesDistVmaxMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_vmax_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 100 &&
                                zone >= 0 &&
                                zone >=
                                  Number(
                                    form.getFieldValue("zonesDistVmaxMin5")
                                  ))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 100 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesDistVmaxMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("M/min zones")} key="6">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit6}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesMminMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesMminMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesMminMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesMminMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesMminMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesMminMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  t(
                                    "Must be between 0 and 200 and less than zone max 2"
                                  )
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesMminMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesMminMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesMminMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesMminMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesMminMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesMminMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesMminMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesMminMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesMminMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesMminMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesMminMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesMminMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesMminMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_mmin_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesMminMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesMminMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("Power zones")} key="7">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit7}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesPowerMin1"
                      label={t("Zone 1 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_min"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            console.log(form.getFieldValue("zonesAccMax1"));
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesPowerMax1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMin1" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesPowerMax1"
                      label={t("Zone 1 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_max"][0]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },

                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesPowerMin1")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 1"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMax1" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesPowerMin2"
                      label={t("Zone 2 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_min"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesPowerMax2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  t(
                                    "Must be between 0 and 200 and less than zone max 2"
                                  )
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMin2" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesPowerMax2"
                      label={t("Zone 2 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_max"][1]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            console.log(form.getFieldValue("zonesAccMax1"));
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesPowerMin2")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 2"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMax2" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesPowerMin3"
                      label={t("Zone 3 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_min"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesPowerMax3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMin3" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesPowerMax3"
                      label={t("Zone 3 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_max"][2]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesPowerMin3")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 3"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMax3" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesPowerMin4"
                      label={t("Zone 4 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_min"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesPowerMax4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMin4" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesPowerMax4"
                      label={t("Zone 4 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_max"][3]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesPowerMin4")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 4"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMax4" disabled={loading} />
                    </Form.Item>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="zonesPowerMin5"
                      label={t("Zone 5 Minimum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_min"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone <
                                  Number(form.getFieldValue("zonesPowerMax5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and less than zone max 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMin5" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="zonesPowerMax5"
                      label={t("Zone 5 Maximum value")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[0]["zone_puissance_max"][4]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                        {
                          validator: (_, zone) => {
                            if (
                              zone === null ||
                              (zone <= 200 &&
                                zone >= 0 &&
                                zone >=
                                  Number(form.getFieldValue("zonesPowerMin5")))
                            ) {
                              return Promise.resolve();
                            } else
                              return Promise.reject(
                                t(
                                  "Must be between 0 and 200 and greater than zone min 5"
                                )
                              );
                          },
                        },
                      ]}
                    >
                      <Input name="zonesPowerMax5" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header="Sprints" key="8">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit8}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="thresoldSprint"
                      label={t("Sprints thresold")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[1]["seuil_sprint"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="thresoldSprint" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="durSprint"
                      label={t("Minimum duration of a sprint")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[1]["dur_sprint"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="durSprint" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("Change of direction")} key="9">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit9}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="angleChange"
                      label={t("Change of angle (degrees)")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[1]["seuil_chgt_dir"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="angleChange" disabled={loading} />
                    </Form.Item>
                    <Form.Item
                      name="thresoldSpeed"
                      label={t("Speed thresold for direction change (m/s)")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[1]["seuil_v_chgt_dir"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="thresoldSpeed" disabled={loading} />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
              <Panel header={t("Power events")} key="10">
                <Form
                  name="session_form"
                  initialValues={{ remember: true }}
                  form={form}
                  onFinish={onSubmit10}
                  onChange={() => form.validateFields()}
                  onClick={(e) => e.stopPropagation()}
                  onFinishFailed={onSubmitFailed}
                  className="session_form settings"
                  layout="vertical"
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      name="minDuration"
                      label={t("Minimum duration of power events")}
                      className="session_input_text"
                      style={{ flex: "1" }}
                      initialValue={currentData[1]["min_dur_power_event"]}
                      rules={[
                        {
                          pattern: new RegExp(/^\d*\.?\d*$/),

                          message: t("Only numbers allowed"),
                        },
                      ]}
                    >
                      <Input name="minDuration" disabled={loading} />
                    </Form.Item>
                    <div></div>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                  >
                    {t("Save")}
                  </Button>
                </Form>
              </Panel>
            </Collapse>
          )}
        {user &&
          user.roles.filter((obj) => obj.name === "athlete").length === 0 &&
          currentData.length && (
            <Button
              type="primary"
              style={{ marginTop: "5%", marginBottom: "5%" }}
              onClick={onSubmit11}
              className="session-form-button"
            >
              {t("Restore default files")}
            </Button>
          )}
      </div>
    </div>
  );
};

export default withTranslation()(SettingsUnits);
