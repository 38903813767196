import * as React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Spin } from "antd";
import "./ViewByRecordMultiple.css";
import RecordListBySessionMultiple from "../components/multiple-trackers/list/RecordListBySessionMultiple";
import { withTranslation } from "react-i18next";

let athleteId;

const ViewByRecordMultiple = (props) => {
  const [recordsBySession, setRecordsBySession] = useState([]);
  const [loadingSession, setLoadingSession] = useState(true);
  const [count, setCount] = useState(0);

  const { id } = useParams();
  const userId = id;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        setCount(response.data.athletes.length);
        localStorage.setItem("keyLang", response.data.language);
        localStorage.setItem("speedUnitFactor", response.data.speedUnit);
        localStorage.setItem("distanceUnitFactor", response.data.distanceUnit);
        localStorage.setItem(
          "indicators",
          JSON.stringify(response.data.indicatorsList)
        );
        let athletes = response.data.athletes;

        Promise.all([
          athletes.map(async (athlete) => {
            athleteId = athlete["id"];
            console.log("athlete: ", id);

            await axios
              .get(
                `${process.env.REACT_APP_SERVER_URL}api/v1/records/athlete/${athleteId}`,
                {
                  headers: { "x-access-token": accessToken },
                }
              )
              .then((res) => {
                if (res.data.length !== 0) {
                  setRecordsBySession((prevState) => [...prevState, res.data]);
                }
              });
          }),
        ]).then(() => setLoadingSession(false));
      });
  }, []);

  const { t } = props;

  return loadingSession ? (
    <Spin tip={t("Loading ...")}></Spin>
  ) : (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("Sessions")}</h1>

      <div className="mainContainer">
        <RecordListBySessionMultiple recordsWithTracker={recordsBySession} />
      </div>
    </div>
  );
};

export default withTranslation()(ViewByRecordMultiple);
