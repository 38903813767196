import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Modal } from "antd";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";
import { useParams } from "react-router-dom";

function PopupTrackerEdit(props) {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [form] = Form.useForm();
  const { tracker, isMobile } = props;
  const Option = Select.Option;

  const { id } = useParams();
  const userId = id;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        console.log(response.data.athletes);
        console.log(
          response.data.athletes.filter((usr) => {
            return usr.id !== Number(userId);
          })
        );
        var returned = response.data.athletes.filter((usr) => {
          return usr.id !== Number(userId);
        });

        setAthletes(returned);

        setLoading(false);
      });
  }, []);

  const onSubmit = (form_data) => {
    setLoading(true);
    setVisible(false);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}api/v1/tracker/${tracker.id}`,

        {
          name: form_data.name,
          athleteId: Number(form_data.athlete),
        },
        { headers: { "x-access-token": accessToken } }
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    window.location.reload();
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div>
      {!isMobile ? (
        <EditOutlined
          style={{
            fontSize: "14px",
            color: "#d5bb64",
            flex: 1,
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: "auto",
          }}
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        />
      ) : (
        <p
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        >
          {t("Edit a tracker")}
        </p>
      )}
      <Modal
        className="athlete-modal"
        visible={visible}
        title={t("Edit a tracker")}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
        footer={null}
      >
        <Form
          name="session_form"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onSubmit}
          onClick={(e) => e.stopPropagation()}
          onFinishFailed={onSubmitFailed}
          className="session_form"
          layout="vertical"
        >
          <Form.Item
            initialValue={tracker.name}
            name="name"
            label={t("Name")}
            rules={[{ required: true, message: t("Please enter name") }]}
            className="session_input_text"
          >
            <Input name="name" disabled />
          </Form.Item>
          <Form.Item
            initialValue={tracker.athlete && tracker.athlete.name}
            name="athlete"
            label={t("Athlete")}
            className="session_input_text"
            style={{ paddingLeft: "1%" }}
          >
            <Select
              showSearch
              name="athlete"
              placeholder={t("Please enter athlete")}
            >
              {athletes.map((athl) => (
                <Option value={athl.id}>{athl.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="session-form-button"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default withTranslation()(PopupTrackerEdit);
