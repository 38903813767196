import React from "react";
import { Layout } from "antd";

const { Header, Content } = Layout;

export default function PublicLayout(props) {
  return (
    <Layout className="public_layout">
      <Header className="public_layout_header">
        <img src={require("../assets/logo-white-main.png")} alt="McLloyd" />
      </Header>
      <Content className="public_layout_content">{props.children}</Content>
    </Layout>
  );
}
