import React from "react";
// the hoc
import { withTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import PrivateRoute from "./services/privateRoute";
import Login from "./pages/Login";
import ViewByRecordMultiple from "./pages/ViewByRecordMultiple";
import IndicatorSessionMultiple from "./pages/IndicatorSessionMultiple";
import BluetoothSendData from "./pages/BluetoothSendData";
import PublicLayout from "./components/publicLayout";
import PrivateLayout from "./components/privateLayout";
import Details from "./pages/Details";
import Tutorials from "./pages/Tutorials";
import SettingsUnits from "./pages/SettingsUnits";
import Graph from "./pages/Graph";
import AdminDash from "./pages/AdminDash";
import ManageAthletes from "./pages/ManageAthletes";
import SessionsRaw from "./pages/SessionsRaw";
import ManageTrackers from "./pages/ManageTrackers";
// css
import "antd/dist/antd.css";
import "./styles/_vars.css";
import "./styles/base.css";
import "./styles/Login.css";
import "./styles/public_layout.css";
import "./styles/private_layout.css";
import "./styles/details.css";

function App({ t }) {
  return (
    <>
      <Router>
        <Routes>
          {/* public pages */}

          <Route
            exact
            path="/"
            element={
              <PublicLayout>
                <Login />
              </PublicLayout>
            }
          />
          <Route path="/admin/*" element={<AdminDash t={t} />} />
          <Route
            path="/:id/download"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <BluetoothSendData t={t} />
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/:id/multiple-tracker-records"
            element={
              <PrivateRoute>
                <PrivateLayout>
                  <ViewByRecordMultiple t={t} />
                </PrivateLayout>
              </PrivateRoute>
            }
          />
          <Route
            path="/:id/manage-athletes"
            element={
              <PrivateLayout>
                <ManageAthletes t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/manage-trackers"
            element={
              <PrivateLayout>
                <ManageTrackers t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/raw-sessions"
            element={
              <PrivateLayout>
                <SessionsRaw t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/indicatorsSession-multiple"
            element={
              <PrivateLayout>
                <IndicatorSessionMultiple t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/graph"
            element={
              <PrivateLayout>
                <Graph t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/settings-units"
            element={
              <PrivateLayout>
                <SettingsUnits t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/details"
            element={
              <PrivateLayout>
                <Details t={t} />
              </PrivateLayout>
            }
          />
          <Route
            path="/:id/tutorials"
            element={
              <PrivateLayout>
                <Tutorials t={t} />
              </PrivateLayout>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default withTranslation()(App);
