import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PopupTrackerEdit from "../components/PopupTrackerEdit";
import PopupTrackerDelete from "../components/PopupTrackerDelete";
import { Select, Spin, Table } from "antd";
import { withTranslation } from "react-i18next";

const ManageTrackers = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trackers, setTrackers] = useState([]);

  const { id } = useParams();

  const Option = Select.Option;

  const coachId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    console.log("HELLO");
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/coach/${coachId}`,
        { headers: { "x-access-token": accessToken } }
      )
      .then((response) => {
        setTrackers(response.data);
        setIsLoading(false);
      });
  }, []);

  const { t } = props;

  console.log(trackers);

  const columns = [
    {
      title: t("N° Tracker"),
      dataIndex: "id",
      key: "id",
      responsive: ["sm"],
      render: (text) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>{text}</div>
      ),
    },
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
      render: (text) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>{text}</div>
      ),
    },
    {
      title: t("Athlete"),
      key: "athlete",
      responsive: ["sm"],
      render: (_, tracker) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          {tracker.athlete && tracker.athlete.name}
        </div>
      ),
    },
    {
      title: t("Actions"),
      key: "data",
      responsive: ["sm"],
      render: (_, tracker) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            paddingTop: "0%",
            lineHeight: "100%",
            marginLeft: "2%",
          }}
        >
          <p
            style={{
              marginRight: "0%",
              marginTop: "3%",
              marginBottom: "3%",
              flex: "1",
            }}
          >
            <PopupTrackerEdit tracker={tracker} />
          </p>
          <p style={{ flex: "1", marginTop: "3%", marginBottom: "3%" }}>
            <PopupTrackerDelete tracker={tracker} />
          </p>
        </div>
      ),
    },
    {
      title: "",
      key: "responsive",
      responsive: ["xs"],
      render: (_, tracker) => {
        return (
          <div style={{ textAlign: "left" }}>
            <p>
              <span style={{ color: "#d5bb64" }}>{t("Tracker")}</span> :{" "}
              {tracker.id}
            </p>
            <br />
            <p>
              <span style={{ color: "#d5bb64" }}>{t("Name")}</span> :{" "}
              {tracker.name}
            </p>
            <br />
            <p>
              <span style={{ color: "#d5bb64" }}>{t("Athlete")}</span> : {""}
              {tracker.athlete && tracker.athlete.name}
            </p>
            <br />

            <br />

            <Select
              style={{ width: "65%" }}
              name="Actions"
              placeholder={t("Actions")}
            >
              <Option style={{ height: "10px" }} value={"A"}>
                <PopupTrackerEdit isMobile={true} tracker={tracker} />
              </Option>
              <Option style={{ height: "10px" }} value={"B"}>
                <PopupTrackerDelete isMobile={true} tracker={tracker} />
              </Option>
            </Select>
          </div>
        );
      },
    },
  ];

  return isLoading ? (
    <Spin tip={t("Loading ...")}></Spin>
  ) : (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("Trackers & tags")}</h1>
      <div className="mainContainer">
        <div
          style={{ textAlign: "left", marginBottom: "5%", paddingTop: "5%" }}
        >
          <Table dataSource={trackers} columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ManageTrackers);
