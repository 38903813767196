import React, { useState } from "react";
import { Modal, Spin, Select } from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";

function PopupRecordDelete(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { session, isMobile } = props;

  const Option = Select.Option;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}api/v1/records/${session.id}`,
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((response) => {
        console.log("Success delete element");

        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div>
      {!isMobile ? (
        <DeleteOutlined
          style={{
            fontSize: "14px",
            color: "#d5bb64",
            flex: 1,
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: "auto",
          }}
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        />
      ) : (
        <Select
          style={{ width: "65%" }}
          name="Actions"
          placeholder={t("Actions")}
        >
          <Option style={{ height: "10px" }} value={""}>
            <p
              onClick={(event) => {
                event.stopPropagation();
                showModal();
              }}
            >
              {t("Delete")}
            </p>
          </Option>
        </Select>
      )}
      {loading ? (
        <>
          <Modal
            className="athlete-modal"
            visible={loading}
            onCancel={handleCancel}
            cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
            footer={null}
          >
            <Spin tip={t("Deleting ...")}></Spin>
          </Modal>
        </>
      ) : (
        <Modal
          visible={visible}
          title={t("Are you sure you really want to delete this session ?")}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
        ></Modal>
      )}
    </div>
  );
}

export default withTranslation()(PopupRecordDelete);
