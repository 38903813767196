export const displayedIndicators = [
  "temps.duree_seance",
  "course.D",
  "course.d_sprintee",
  "course.m_min",
  "course.V_max",
  "sprint.nb_sprint",
  "sprint.duree_moy",
  "sprint.dist_moy",
  "sprint.v_moy",
  "sprint.duree_max",
  "sprint.dist_max",
  "sprint.v_max",
  "acc.acc_max_liste",
  "acc.dec_max_liste",
  "acc.nb_acc_liste",
  "acc.nb_dec_liste",
  "acc.acc_moy_liste",
  "acc.dec_moy_liste",
  "acc.dist_moy_suite_acc_liste",
  "acc.dist_max_suite_acc_liste",
  "dir.nb_dir_liste",
  "autre.qsignal_moy",
  "temps.duree_data",
  "temps.duree_gps",
  "course.V_moy",
  "power.nb_event",
  "power.dur_moy",
  "power.p_moy",
  "power.temps_moy_entre",
  "dp.puissance_moy_aerobie",
  "dp.puissance_max",
  "autre.hdop_max",
  "autre.hdop_min",
  "autre.hdop_moy",
  "ffr.dist_en_acceleration",
  "ffr.dist_en_deceleration",
  "ffr.acceleration_max",
  "ffr.deceleration_max",
  "dp.distance_eq",
  "course.dist_zone[0]",
  "course.dist_zone[1]",
  "course.dist_zone[2]",
  "course.dist_zone[3]",
  "course.dist_zone[4]",
  "course.dist_pourc_zone[0]",
  "course.dist_pourc_zone[1]",
  "course.dist_pourc_zone[2]",
  "course.dist_pourc_zone[3]",
  "course.dist_pourc_zone[4]",
  "course.dist_zone_vmax[0]",
  "course.dist_zone_vmax[1]",
  "course.dist_zone_vmax[2]",
  "course.dist_zone_vmax[3]",
  "course.dist_zone_vmax[4]",
  "course.temps_zone_mmin[0]",
  "course.temps_zone_mmin[1]",
  "course.temps_zone_mmin[2]",
  "course.temps_zone_mmin[3]",
  "course.temps_zone_mmin[4]",
  "course.dist_zone_vma[0]",
  "course.dist_zone_vma[1]",
  "course.dist_zone_vma[2]",
  "course.dist_zone_vma[3]",
  "course.dist_zone_vma[4]",
  "course.dist_pourc_zone_vma[0]",
  "course.dist_pourc_zone_vma[1]",
  "course.dist_pourc_zone_vma[2]",
  "course.dist_pourc_zone_vma[3]",
  "course.dist_pourc_zone_vma[4]",
  "course.temps_zone[0]",
  "course.temps_zone[1]",
  "course.temps_zone[2]",
  "course.temps_zone[3]",
  "course.temps_zone[4]",
  "course.temps_pourc_zone[0]",
  "course.temps_pourc_zone[1]",
  "course.temps_pourc_zone[2]",
  "course.temps_pourc_zone[3]",
  "course.temps_pourc_zone[4]",
];

export const translate = {
  "temps.duree_seance": "Record Duration",
  "course.D": "Total distance",
  "course.d_sprintee": "Sprints total distance",
  "course.m_min": "Tempo (m/min)",
  "course.V_max": "Max speed",
  "sprint.nb_sprint": "Number of sprints",
  "sprint.duree_moy": "Average duration of sprints",
  "sprint.dist_moy": "Average distance of sprints",
  "sprint.v_moy": "Average speed of sprints",
  "sprint.duree_max": "Max. duration of a sprint",
  "sprint.dist_max": "Max. distance of a sprint",
  "sprint.v_max": "Max. speed of a sprint",
  "acc.acc_max_liste": "Max. acceleration (m/s2)",
  "acc.dec_max_liste": "Max. deceleration (m/s2)",
  "acc.nb_acc_liste": "Number of accelerations",
  "acc.nb_dec_liste": "Number of decelerations",
  "acc.acc_moy_liste": "Average acceleration (m/s2)",
  "acc.dec_moy_liste": "Average deceleration (m/s2)",
  "acc.dist_moy_suite_acc_liste": "Average distance of accelerations",
  "acc.dist_max_suite_acc_liste": "Average distance of decelerations",
  "dir.nb_dir_liste": "Number of changes of directions",
  "meta_cardio.EPOC_max": "Max. EPOC",
  "autre.qsignal_moy": "GPS-Average quality of signal",
  "temps.duree_data": "Data duration",
  "temps.duree_gps": "GPS duration",
  "course.V_moy": "Average speed",
  "power.nb_event": "Number of power events",
  "power.dur_moy": "Average duration of power events",
  "power.p_moy": "Average power of power events (W)",
  "power.temps_moy_entre": "Av. duration between 2 power events",
  "dp.puissance_moy_aerobie": "Aerobic average power (W)",
  "dp.puissance_max": "Di Prampero max power (W)",
  "autre.hdop_max": "Max. HPOP",
  "autre.hdop_min": "Min. HDOP",
  "autre.hdop_moy": "Average HDOP",
  "ffr.dist_en_acceleration": "Distance of accelerations",
  "ffr.dist_en_deceleration": "Distance of decelerations",
  "ffr.acceleration_max": "Max. acceleration (m/s2)",
  "ffr.deceleration_max": "Max. deceleration (m/s2)",
  "dp.distance_eq": "Di prampero equivalent distance",
  "course.dist_zone[0]": "Distance covered in speed zone 1 (m)",
  "course.dist_zone[1]": "Distance covered in speed zone 2 (m)",
  "course.dist_zone[2]": "Distance covered in speed zone 3 (m)",
  "course.dist_zone[3]": "Distance covered in speed zone 4 (m)",
  "course.dist_zone[4]": "Distance covered in speed zone 5 (m)",
  "course.dist_pourc_zone[0]": "Distance covered in speed zone 1 (%)",
  "course.dist_pourc_zone[1]": "Distance covered in speed zone 2 (%)",
  "course.dist_pourc_zone[2]": "Distance covered in speed zone 3 (%)",
  "course.dist_pourc_zone[3]": "Distance covered in speed zone 4 (%)",
  "course.dist_pourc_zone[4]": "Distance covered in speed zone 5 (%)",
  "course.dist_zone_vmax[0]": "Distance covered in max speed zone 1 (m)",
  "course.dist_zone_vmax[1]": "Distance covered in max speed zone 2 (m)",
  "course.dist_zone_vmax[2]": "Distance covered in max speed zone 3 (m)",
  "course.dist_zone_vmax[3]": "Distance covered in max speed zone 4 (m)",
  "course.dist_zone_vmax[4]": "Distance covered in max speed zone 5 (m)",
  "course.temps_zone_mmin[0]": "Time spent in tempo zone 1 (m/min)",
  "course.temps_zone_mmin[1]": "Time spent in tempo zone 2 (m/min)",
  "course.temps_zone_mmin[2]": "Time spent in tempo zone 3 (m/min)",
  "course.temps_zone_mmin[3]": "Time spent in tempo zone 4 (m/min)",
  "course.temps_zone_mmin[4]": "Time spent in tempo zone 5 (m/min)",
  "course.dist_zone_vma[0]": "Distance covered in VMA Zone 1 (m)",
  "course.dist_zone_vma[1]": "Distance covered in VMA Zone 2 (m)",
  "course.dist_zone_vma[2]": "Distance covered in VMA Zone 3 (m)",
  "course.dist_zone_vma[3]": "Distance covered in VMA Zone 4 (m)",
  "course.dist_zone_vma[4]": "Distance covered in VMA Zone 5 (m)",
  "course.dist_pourc_zone_vma[0]": "Distance covered in VMA Zone 1 (%)",
  "course.dist_pourc_zone_vma[1]": "Distance covered in VMA Zone 2 (%)",
  "course.dist_pourc_zone_vma[2]": "Distance covered in VMA Zone 3 (%)",
  "course.dist_pourc_zone_vma[3]": "Distance covered in VMA Zone 4 (%)",
  "course.dist_pourc_zone_vma[4]": "Distance covered in VMA Zone 5 (%)",
  "course.temps_zone[0]": "Time spent in VMA Zone 1 (min)",
  "course.temps_zone[1]": "Time spent in VMA Zone 2 (min)",
  "course.temps_zone[2]": "Time spent in VMA Zone 3 (min)",
  "course.temps_zone[3]": "Time spent in VMA Zone 4 (min)",
  "course.temps_zone[4]": "Time spent in VMA Zone 5 (min)",
  "course.temps_pourc_zone[0]": "Time spent in VMA Zone 1 (%)",
  "course.temps_pourc_zone[1]": "Time spent in VMA Zone 2 (%)",
  "course.temps_pourc_zone[2]": "Time spent in VMA Zone 3 (%)",
  "course.temps_pourc_zone[3]": "Time spent in VMA Zone 4 (%)",
  "course.temps_pourc_zone[4]": "Time spent in VMA Zone 5 (%)",
};
