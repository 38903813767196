import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Select, Spin, Input, Table } from "antd";
import { withTranslation } from "react-i18next";
import PopupSessionEdit from "../components/PopupSessionEdit";
import PopupSessionDelete from "../components/PopupSessionDelete";
import BluetoothSendData from "./BluetoothSendData";
import {
  sessionToFormatedEndDateTime,
  sessionToFormatedStartDateTime,
  sessionToStartUTCDate,
} from "../services/date.service";

const SessionsRaw = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [days, setDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [months, setMonths] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const [years, setYears] = useState([2023, 2022, 2021]);
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(null);

  let tmp = 0;

  const Option = Select.Option;
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  const onDownloadSuccess = () => {};

  const { t } = props;

  const { id } = useParams();

  const userId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    console.log("--------------------Render--------------");
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        setUser(response.data);
        Promise.all([
          response.data.athletes.map(async (athlete) => {
            var id = athlete["id"];
            await axios
              .get(
                `${process.env.REACT_APP_SERVER_URL}api/v1/sessions/athlete/${id}`,
                { headers: { "x-access-token": accessToken } }
              )
              .then((res) => {
                if (res.data.length !== 0) {
                  console.log("SESS : ", res.data);
                  setSessions((sessions) => sessions.concat(res.data));
                }
              });
          }),
        ]).then(() => setIsLoading(false));
      });
  }, []);

  const columns = [
    {
      title: t("Athlete"),
      key: "athlete",
      responsive: ["sm"],
      render: (record) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          {record.athlete.name}
        </div>
      ),
    },
    {
      title: t("N° Tracker"),
      key: "id",
      responsive: ["sm"],
      render: (record) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          {record.tracker && record.tracker.name}
        </div>
      ),
    },
    {
      title: t("Start"),
      key: "start",
      responsive: ["sm"],
      render: (_, record) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          {sessionToFormatedStartDateTime(record)}
        </div>
      ),
    },
    {
      title: t("End"),
      key: "end",
      responsive: ["sm"],
      render: (_, record) => (
        <div style={{ marginTop: "3%", marginBottom: "3%" }}>
          {sessionToFormatedEndDateTime(record)}
        </div>
      ),
    },

    {
      title: t("Analysed"),
      dataIndex: "analysed",
      key: "analysed",
      responsive: ["sm"],
      render: (text) =>
        text ? (
          <div style={{ marginTop: "3%", marginBottom: "3%" }}>{t("Yes")}</div>
        ) : (
          <div style={{ marginTop: "3%", marginBottom: "3%" }}>{t("No")}</div>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["sm"],
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            paddingTop: "0%",
            lineHeight: "100%",
            marginLeft: "2%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <p
            style={{
              marginRight: "0%",
              margin: "auto",
              flex: "1",
            }}
          >
            <PopupSessionEdit isMobile={false} session={record} />
          </p>
          {user &&
            user.roles.filter((obj) => obj.name === "athlete").length === 0 && (
              <p style={{ flex: "1", margin: "auto" }}>
                <PopupSessionDelete isMobile={false} session={record} />
              </p>
            )}
        </div>
      ),
    },
    {
      title: "",
      responsive: ["xs"],
      render: (_, record) => {
        return (
          <div style={{ textAlign: "left" }}>
            <p>
              <span style={{ color: "#d5bb64" }}>{"Athlete"}</span> :{" "}
              {record.athlete.name}
            </p>
            <br />
            <p>
              <span style={{ color: "#d5bb64" }}>{t("Tracker")}</span> :{" "}
              {record.tracker && record.tracker.name}
            </p>
            <br />
            <p>
              <span style={{ color: "#d5bb64" }}>{t("Start")}</span> :{" "}
              {sessionToFormatedStartDateTime(record)}
            </p>
            <br />
            <p>
              <span style={{ color: "#d5bb64" }}>{t("End")}</span> : {""}
              {sessionToFormatedEndDateTime(record)}
            </p>
            <br />

            {record.analysed ? (
              <p>
                <span style={{ color: "#d5bb64" }}>{t("Analysed")}</span> :{" "}
                {t("Yes")}
              </p>
            ) : (
              <p>
                <span style={{ color: "#d5bb64" }}>{t("Analysed")}</span> :{" "}
                {t("No")}
              </p>
            )}

            <br />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <PopupSessionEdit
                style={{ height: "10px" }}
                isMobile={true}
                session={record}
              />

              {user &&
                user.roles.filter((obj) => obj.name === "athlete").length ===
                  0 && (
                  <PopupSessionDelete
                    style={{ height: "10px" }}
                    isMobile={true}
                    session={record}
                  />
                )}
            </div>
          </div>
        );
      },
    },
  ];

  const sessionFilteredAndSorted = sessions
    .sort((a, b) =>
      b.startTime > a.startTime ? 1 : a.startTime > b.startTime ? -1 : 0
    )
    .filter((record) => {
      const startUTCDate = sessionToStartUTCDate(record);
      return (
        (!days || days.includes(startUTCDate.getDay())) &&
        (!months || months.includes(startUTCDate.getMonth())) &&
        (!years || years.includes(startUTCDate.getFullYear())) &&
        (!search ||
          (record.athlete &&
            record.athlete.name.toLowerCase().indexOf(search.toLowerCase()) >
              -1))
      );
    });

  return isLoading ? (
    <Spin tip="Loading ..."></Spin>
  ) : (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("Records")}</h1>
      <div className="mainContainer">
        <div style={{ textAlign: "left", marginBottom: "3%" }}>
          <BluetoothSendData onSuccess={onDownloadSuccess} />
        </div>
        <div style={{ textAlign: "left", marginBottom: "3%" }}>
          <Input
            style={{
              width: "auto",
              backgroundColor: "transparent",
              border: "1px solid",
            }}
            name="name"
            placeholder={t("Search by name")}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <div style={{ textAlign: "left", marginBottom: "3%" }}>
          <div className="selectSubContainer">
            <Select
              mode="multiple"
              onChange={(e) => {
                if ((e && e.length && e.includes("all")) || e.length === 0) {
                  setDays([0, 1, 2, 3, 4, 5, 6]);
                } else setDays(e);
              }}
              allowClear
              placeholder={t("Days")}
              className="selector"
            >
              {[
                t("Sunday"),
                t("Monday"),
                t("Tuesday"),
                t("Wednesday"),
                t("Thursday"),
                t("Friday"),
                t("Saturday"),
              ].map((day, index) => (
                <Option value={index} key={day}>
                  {day}
                </Option>
              ))}
              <Option value="all">{t("All")}</Option>
            </Select>

            <Select
              mode="multiple"
              onChange={(e) => {
                if ((e && e.length && e.includes("all")) || e.length === 0) {
                  setMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
                } else setMonths(e);
              }}
              allowClear
              placeholder={t("Months")}
              className="selector"
            >
              {[
                t("January"),
                t("February"),
                t("March"),
                t("April"),
                t("May"),
                t("June"),
                t("July"),
                t("August"),
                t("September"),
                t("October"),
                t("November"),
                t("December"),
              ].map((month, index) => (
                <Option value={index}>{month}</Option>
              ))}
              <Option value="all">{t("All")}</Option>
            </Select>

            <Select
              mode="multiple"
              onChange={(e) => {
                if ((e && e.length && e.includes("all")) || e.length === 0) {
                  setYears([2023, 2022, 2021]);
                } else setYears(e);
              }}
              allowClear
              placeholder={t("Years")}
              className="selector"
              style={{ marginRight: "0%" }}
            >
              {[2023, 2022, 2021].map((year) => (
                <Option value={year} key={year}>
                  {year}
                </Option>
              ))}
              <Option value="All" key="All years">
                {t("All")}
              </Option>
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          pagination={false}
          rowKey="id"
          dataSource={sessionFilteredAndSorted}
        />
      </div>
    </div>
  );
};

export default withTranslation()(SessionsRaw);
