import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useProvideAuth } from "./auth.service";
import { Spin } from "antd";
import { withTranslation } from "react-i18next";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children }) => {
  let auth = useProvideAuth();
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    if (!auth.user) {
      auth.auth_from_storage().then((ret) => {
        auth.user = ret;
        set_loading(false);
      });
    } else {
      set_loading(false);
    }
    return () => {
      set_loading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Spin tooltip="Loading ..." />
      ) : localStorage.getItem("accessToken") ? (
        children
      ) : (
        <Navigate
          to={{
            pathname: "/",
          }}
        />
      )}
    </>
  );
};

export default withTranslation()(PrivateRoute);
