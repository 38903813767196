import React, { useState, useContext, createContext } from "react";
import axios from "axios";

const authContext = createContext();

// Provider component that wraps app and makes auth object
// available to any child component that calls useAuth()
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [user, set_user] = useState(false);

  const login = (email, password) => {
    return axios
      .post(process.env.REACT_APP_SERVER_URL + "api/v1/auth/signin", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("userId", JSON.stringify(response.data.id));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.accessToken)
          );
        }
        set_user(response.data);
        console.log(response.data);
        return response.data;
      });
  };

  const logout = () => {
    localStorage.removeItem("userId");
    set_user(false);
    localStorage.clear();
  };

  const auth_from_storage = async () => {
    let u = localStorage.getItem("userId");
    if (u) {
      return await axios({
        url: process.env.REACT_APP_SERVER_URL + "api/v1/auth/checklogged",
        method: "post",
        headers: { "x-access-token": localStorage.getItem("accessToken") },
      })
        .then((response) => {
          set_user(u);
          return true;
        })
        .catch((err) => {
          set_user(false);
          localStorage.removeItem("userId");
          return false;
        });
    }
    return false;
  };

  return {
    user,
    login,
    logout,
    auth_from_storage,
  };
}
