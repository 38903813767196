import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col, Spin, Input } from "antd";
import "./Details.css";

const Details = (props) => {
  const isMobile = window.innerWidth < 800;
  const { t } = props;
  return (
    <>
      {" "}
      {isMobile ? (
        <>
          <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
            <h1>Trackers</h1>
            <div className="mainContainer">
              <div
                style={{
                  textAlign: "left",
                  marginBottom: "5%",
                  paddingTop: "5%",
                }}
              >
                <div style={{ textAlign: "left", marginBottom: "5%" }}>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-green-fixed.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Fixed light : When charging - Tracker is fully charged - It can be unplugged."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-green-slow.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Slow flash : Tracker is ready to pair to bluetooth."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-white-slow.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Slow flash : Tracker has detected enough satellites. The recording starts."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-white-fast.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Fast flash : Tracker is looking for more satellites"
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-red-fast.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Fast flash : Battery level is critic or memory is full. It needs charging."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-red-fixed.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t("Fixed light : Tracker is charging")}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-red-slow.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t("Slow flash : Battery level is low")}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-pink-fixed.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Fixed light : Tracking is updating or under reinitialization. Please wait."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-blue-slow.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t(
                          "Slow flash : Tracker is connected to mobile app. It can transfer data."
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        verticalAlign: "middle",
                        width: !isMobile ? "40%" : "50%",
                      }}
                    >
                      <img
                        src={require("../assets/picto-blue-fast.png")}
                        style={{
                          fontSize: "100%",
                          flex: 3,
                          cursor: "pointer",
                        }}
                        alt="profile"
                      />
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px !important",
                          flex: "1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {t("Fast flash : Tracker is ready for pairing")}
                      </p>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
          <h1>Trackers</h1>
          <div className="mainContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: !isMobile ? "15%" : "0%",
                marginLeft: !isMobile ? "15%" : "0px",
                paddingLeft: !isMobile ? "7%" : "0px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "27%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="green fixed"
                  src={require("../assets/picto-green-fixed.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t(
                    "Fixed light : When charging - Tracker is fully charged - It can be unplugged."
                  )}
                </p>
              </div>
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "15%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="green slow"
                  src={require("../assets/picto-green-slow.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t("Slow flash : Tracker is ready to pair to bluetooth.")}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: !isMobile ? "15%" : "0%",
                marginLeft: !isMobile ? "15%" : "0px",
                paddingLeft: !isMobile ? "7%" : "0px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "27%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="white slow"
                  src={require("../assets/picto-white-slow.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t(
                    "Slow flash : Tracker has detected enough satellites. The recording starts."
                  )}
                </p>
              </div>
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "15%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="white fast"
                  src={require("../assets/picto-white-fast.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t("Fast flash : Tracker is looking for more satellites")}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: !isMobile ? "15%" : "0%",
                marginLeft: !isMobile ? "15%" : "0px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: "5%",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "15%" : "0%",
                  width: !isMobile ? "10%" : "32%",
                  paddingLeft: !isMobile ? "0px" : "3%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ flex: 1 }}
                  alt="red fast"
                  src={require("../assets/picto-red-fast.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t(
                    "Fast flash : Battery level is critic or memory is full. It needs charging."
                  )}
                </p>
              </div>
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: "5%",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "15%" : "0%",
                  width: !isMobile ? "10%" : "32%",
                  paddingLeft: !isMobile ? "0px" : "3%",
                  display: "flex",
                  height: "fit-content",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ flex: 1 }}
                  alt="red fixed"
                  src={require("../assets/picto-red-fixed.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t("Fixed light : Tracker is charging")}
                </p>
              </div>
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: "5%",
                  paddingTop: "5%",
                  width: "10%",
                  display: "flex",
                  paddingLeft: !isMobile ? "0px" : "3%",
                  height: "fit-content",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ flex: 1 }}
                  alt="red slow"
                  src={require("../assets/picto-red-slow.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t("Slow flash : Battery level is low")}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: !isMobile ? "41%" : "32%",
                marginLeft: !isMobile ? "41%" : "32%",
              }}
            >
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: "5%",
                  paddingTop: "5%",
                  width: !isMobile ? "10%" : "34%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{ flex: 1 }}
                  alt="pink fixed"
                  src={require("../assets/picto-pink-fixed.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t(
                    "Fixed light : Tracking is updating or under reinitialization. Please wait."
                  )}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: !isMobile ? "15%" : "0%",
                marginLeft: !isMobile ? "15%" : "0px",
                paddingLeft: !isMobile ? "7%" : "0px",
              }}
            >
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "27%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="blue slow"
                  src={require("../assets/picto-blue-slow.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t(
                    "Slow flash : Tracker is connected to mobile app. It can transfer data."
                  )}
                </p>
              </div>
              <div
                style={{
                  flex: "1",
                  textAlign: "center",
                  marginBottom: !isMobile ? "5%" : "0px",
                  paddingTop: "5%",
                  marginRight: !isMobile ? "15%" : "0px",
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    flex: 1,
                    paddingRight: !isMobile ? "0%" : "11%",
                    marginLeft: !isMobile ? "0%" : "20%",
                  }}
                  alt="blue fast"
                  src={require("../assets/picto-blue-fast.png")}
                />{" "}
                <p
                  style={{ flex: 1, lineHeight: "1" }}
                  className="detailsParagraph"
                >
                  {t("Fast flash : Tracker is ready for pairing")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(Details);
