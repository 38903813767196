import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProvideAuth } from "../services/auth.service";
import { Form, Input, Button, message, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

function Login(props) {
  const [loading, set_loading] = useState(false);
  const auth = useProvideAuth();
  const navigate = useNavigate();

  // form submit
  const onSubmit = (form_data) => {
    set_loading(true);
    auth.login(form_data.login, form_data.password).then(
      () => {
        const userId = localStorage.getItem("userId");
        localStorage.setItem("home", `/${userId}/multiple-tracker-records`);
        navigate(`/${userId}/multiple-tracker-records`, {
          state: {
            userId: userId,
          },
        });
      },
      (error) => {
        const resMessage =
          error.response?.data?.message || error.message || error.toString();
        message.error(resMessage);
        set_loading(false);
      }
    );
  };

  // form failed
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // no login for connected user
  const check_connection = async () => {
    if (auth) {
      if (await auth.auth_from_storage()) {
        const userId = localStorage.getItem("userId");
        navigate(`/${userId}/multiple-tracker-records`, {
          state: {
            userId: userId,
          },
        });
      }
    }
  };

  const { t } = props;
  // useEffect ------------------------------------------------------
  useEffect(() => {
    check_connection();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render ---------------------------------------------------------
  return (
    <div className="public_page_wrapper login">
      <Card className="public_home_overlay login_overlay">
        <Form
          name="login_form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
          className="login_form"
          layout="vertical"
        >
          <Form.Item
            name="login"
            rules={[{ required: true, message: "Please Enter Username" }]}
            label={t("User")}
            className="login_input_text"
          >
            <Input
              disabled={loading}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="login"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("Password")}
            rules={[{ required: true, message: t("Please Enter Password") }]}
            className="login_input_text"
          >
            <Input.Password
              disabled={loading}
              prefix={<LockOutlined style={{ fontSize: 13 }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {t("Login")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div className="version">
        Version {`${process.env.REACT_APP_VERSION}`}
      </div>
    </div>
  );
}

export default withTranslation()(Login);
