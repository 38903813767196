import React from "react";
import { withTranslation } from "react-i18next";

const Tutorials = (props) => {
  const { t } = props;
  return (
    <div style={{ backgroundColor: "#262626" }}>
      {" "}
      <h2
        style={{
          color: "white",
          position: "relative",
          textAlign: "center",
          width: "70%",
          margin: "auto",
          marginTop: "4%",
          lineHeight: "1.2",
          fontWeight: "700",
        }}
      >
        {t("Mclloyd Platform Overview")}
      </h2>
      <div
        style={{
          padding: "75% 0 0 0",
          position: "relative",
          width: "70%",
          margin: "auto",
          marginTop: "6%",
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/786856446?h=4841b01909&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title={t("McLloyd ATv1 - Platform overview")}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <h2
        style={{
          color: "white",
          textAlign: "center",
          position: "relative",
          width: "70%",
          margin: "auto",
          marginTop: "8%",
          lineHeight: "1.2",
          fontWeight: "700",
        }}
      >
        {t("How to record and download data")}
      </h2>
      <div
        style={{
          padding: "75% 0 0 0",
          position: "relative",
          width: "70%",
          margin: "auto",
          marginTop: "6%",
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/786868382?h=f71df9b99c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title={t("McLloyd ATv1 - How to record and download data")}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <h2
        style={{
          color: "white",
          textAlign: "center",
          position: "relative",
          width: "70%",
          margin: "auto",
          marginTop: "8%",
          lineHeight: "1.2",
          fontWeight: "700",
        }}
      >
        {t("How to modify and explore a session")}
      </h2>
      <div
        style={{
          padding: "75% 0 0 0",
          position: "relative",
          width: "70%",
          margin: "auto",
          marginTop: "6%",
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/786277343?h=8b567b570e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title={t("McLloyd ATv1 - How to modify and explore a session")}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

export default withTranslation()(Tutorials);
