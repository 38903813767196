import React from "react";
import "antd/dist/antd.css";
import { withTranslation } from "react-i18next";
import { translate } from "../../utils/indicatorTranslate";
import PopupIndicatorDelete from "../PopupIndicatorDelete";

const DesktopIndicatorTitle = (props) => {
  const {t, indicator, onDelete} = props;
  const styleDiv = {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
  };
  const styleP = {
    flex: "4",
    margin: "auto",
  };
  return (
    <div style={styleDiv}>
      <p style={styleP}>
        {t(translate[indicator])}{" "}
      </p>
      <PopupIndicatorDelete
        onDelete={onDelete}
        style={{ margin: "auto" }}
        indic={indicator}
      />
    </div>
  );
}

export default withTranslation()(DesktopIndicatorTitle);
