
// **********************************************************************************************

exports.formDateObjectToUTCDate = (startDatetime) => {
  return startDatetime
}

// **********************************************************************************************

exports.sessionToStartUTCDate = (session) => {
  return new Date(session.startTime);
}

exports.sessionToEndUTCDate = (session) => {
  return new Date(session.endTime);
}

// **********************************************************************************************

function format2Digits(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
};

function formatedTime(utcDate) {
  const isUsFormat = localStorage.getItem("keyLang") === "en";
  const isAfterNoon = utcDate.getHours() > 12
  const deltaHours = {
    minus: isUsFormat && isAfterNoon ? 12 : 0,
    xm: isUsFormat ? (isAfterNoon ? ' pm' : ' am') : '',
  }
  const hours = format2Digits(utcDate.getHours() - deltaHours.minus);
  const minutes = format2Digits(utcDate.getMinutes());
  const seconds = format2Digits(utcDate.getSeconds());
  return `${hours}:${minutes}:${seconds}${deltaHours.xm}`;
};

function formatedDate(utcDate){
  const locale = localStorage.getItem("keyLang") || "en";
  return utcDate.toLocaleDateString(locale)
}

exports.sessionToFormatedStartDate = (record) => {
  const date = new Date(record.startTime);
  return formatedDate(date);
};

exports.sessionToFormatedStartDateTime = (record) => {
  const utcDate = new Date(record.startTime);
  const date = formatedDate(utcDate);
  const time = formatedTime(utcDate);
  return `${date} - ${time}`;
}

exports.sessionToFormatedEndDateTime = (record) => {
  const utcDate = new Date(record.endTime);
  const date = formatedDate(utcDate);
  const time = formatedTime(utcDate);
  return `${date} - ${time}`;
}

// **********************************************************************************************

