import * as React from "react";
import {
    ArrayField,
    ChipField,
    Datagrid,
    EmailField,
    List,
    SingleFieldList,
    TextField,
    Edit,
    Create,
    EditButton,
    PasswordInput,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
} from "react-admin";

const userFilters = [<TextInput source="email" label="Email" />];
export const UserList = () => (
    <List filters={userFilters} perPage={25}>
        <Datagrid
            sx={{
                backgroundColor: "#DBAC58",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "#fff",
                },
            }}
        >
            <TextField source="id" />
            <TextField source="uuid" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="password" />
            <ArrayField source="trackers">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

const UserForm = (props) => (
    <SimpleForm
        sx={{
            backgroundColor: "#DBAC58",
            "& .RaDatagrid-headerCell": {
                backgroundColor: "#DBAC58",
            },
        }}
        {...props}
    >
        <TextInput disabled source="id" label="ID" />
        <TextInput disabled source="uuid" label="UUID" />
        <TextInput source="name" label="Nom" />
        <PasswordInput source="password" label="Mot de passe" />
        <ReferenceArrayInput
            label="Trackers"
            source="tracker_ids"
            reference="tracker"
        >
            <SelectArrayInput label="Tracker names" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Roles" source="role_ids" reference="roles">
            <SelectArrayInput label="Roles names" />
        </ReferenceArrayInput>
    </SimpleForm>
);

export const UserEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <UserForm />
    </Edit>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm
            sx={{
                backgroundColor: "#DBAC58",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "#DBAC58",
                },
            }}
        >
            <TextInput source="name" label="Nom" />
            <TextInput source="email" label="Email" />
            <PasswordInput source="password" label="Mot de passe" />
            <ReferenceArrayInput
                label="Trackers"
                source="tracker_ids"
                reference="tracker"
            >
                <SelectArrayInput label="Tracker names" />
            </ReferenceArrayInput>
            <ReferenceArrayInput label="Roles" source="role_ids" reference="roles">
                <SelectArrayInput label="Roles names" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
