import React, { useState } from "react";
import { Modal } from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";

function PopupTrackerDelete(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { tracker, isMobile } = props;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}api/v1/tracker/${tracker.id}`,
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((response) => {
        console.log("Success delete element");
        window.location.reload();
      })
      .catch((error) => console.log(error));

    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 1000);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div>
      {!isMobile ? (
        <DeleteOutlined
          style={{
            fontSize: "14px !important",
            color: "#d5bb64",
            flex: 1,
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: "auto",
          }}
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        />
      ) : (
        <p
          onClick={() => {
            showModal();
          }}
        >
          {t("Delete")}
        </p>
      )}
      <Modal
        visible={visible}
        title={t("Are you sure you really want to delete this tracker ?")}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
      ></Modal>
    </div>
  );
}

export default withTranslation()(PopupTrackerDelete);
