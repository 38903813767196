import React, { useState } from "react";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";

function PopupIndicatorDelete(props) {
  const [visible, setVisible] = useState(false);

  const { t, onDelete, indic } = props;
  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };
  const onSubmit = (form_data) => {
    onDelete(indic);

    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  const showModal = () => {
    setVisible(true);
  };

  const isMobile = window.innerWidth < 700;

  return (
    <>
      <CloseCircleOutlined
        style={{
          fontSize: "14px !important",
          color: "white",
          float: !isMobile ? "none" : "inline-end",
          marginTop: !isMobile ? "0%" : "9%",
          marginRight: !isMobile ? "0%" : "0%",
        }}
        onClick={() => {
          showModal();
        }}
      />
      <Modal
        className="athlete-modal"
        visible={visible}
        onCancel={handleCancel}
        onOk={onSubmit}
        okText={t("Delete")}
        cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
        title={t("Delete indicator")}
      ></Modal>
    </>
  );
}

export default withTranslation()(PopupIndicatorDelete);
