import React from "react";
import "antd/dist/antd.css";
import { withTranslation } from "react-i18next";
import { translate } from "../../utils/indicatorTranslate";
import PopupIndicatorDelete from "../PopupIndicatorDelete";

const MobileIndicatorTitle = (props) => {
  const {t, indicator, onDelete} = props;

  const styleDiv = {
    marginTop: "3%",
    marginBottom: "3%",
    display: "flex",
    flexDirection: "column",
  };

  const styleSpan = {
    flex: "1",
  };

  if (indicator === "Athlete") {
    return (
      <div style={styleDiv}>
        <span style={styleSpan}>
          Athlete
        </span>
      </div>
    )
  } else {
    return (
      <div style={styleDiv}>
        <span style={styleSpan}>
          {t(translate[indicator])}
        </span>
          <PopupIndicatorDelete
            style={{ flex: "1" }}
            onDelete={onDelete}
            indic={indicator}
          />
      </div>
    );
  }
}

export default withTranslation()(MobileIndicatorTitle);
