import * as React from "react";
import { withTranslation } from "react-i18next";
import PopupRecordDelete from "../../PopupRecordDelete";

function RecordActionItemBySessionMultiple(props) {
  const {record} = props;
  return (
    <PopupRecordDelete isMobile={false} session={record} key={record.uuid}/>
  );
}

export default withTranslation()(RecordActionItemBySessionMultiple);
