import * as React from "react";
import {
    ArrayField,
    ChipField,
    Datagrid,
    ReferenceArrayInput,
    List,
    SingleFieldList,
    TextField,
    Edit,
    Create,
    EditButton,
    SelectArrayInput,
    SimpleForm,
    TextInput,
} from "react-admin";

const trackerFilters = [<TextInput source="name" label="Name" />];

export const TrackerList = () => (
    <List filters={trackerFilters} perPage={25}>
        <Datagrid
            sx={{
                backgroundColor: "#FFD700",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "#DBAC58",
                },
            }}
            rowClick="edit"
        >
            <TextField source="id" />
            <TextField source="uuid" />
            <TextField source="name" />
            <ArrayField source="users">
                <SingleFieldList>
                    <ChipField source="email" />
                </SingleFieldList>
            </ArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

const TrackerForm = (props) => (
    <SimpleForm
        sx={{
            backgroundColor: "#DBAC58",
            "& .RaDatagrid-headerCell": {
                backgroundColor: "#DBAC58",
            },
        }}
        {...props}
    >
        <TextInput disabled source="id" label="ID" />
        <TextInput disabled source="uuid" label="UUID" />
        <TextInput source="name" label="Nom" />
        <ReferenceArrayInput label="Users" source="user_ids" reference="users">
            <SelectArrayInput label="User email" />
        </ReferenceArrayInput>
    </SimpleForm>
);

export const TrackerEdit = (props) => (
    <Edit {...props} mutationMode="pessimistic">
        <TrackerForm />
    </Edit>
);

export const TrackerCreate = (props) => (
    <Create {...props}>
        <SimpleForm
            sx={{
                backgroundColor: "#DBAC58",
                "& .RaDatagrid-headerCell": {
                    backgroundColor: "#DBAC58",
                },
            }}
        >
            <TextInput source="name" label="Nom" />
            <ReferenceArrayInput label="Users" source="user_ids" reference="users">
                <SelectArrayInput label="Users email" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
