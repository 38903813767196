import React, { useState } from "react";
import { Modal, Button } from "antd";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";

function PopupAthleteDelete(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { athlete } = props;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);

    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${athlete.id}`, {
        headers: { "x-access-token": accessToken },
      })
      .then(() => {
        console.log("Success delete athlete");

        window.location.reload();
      })
      .catch((error) => console.log(error));

    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div style={{ marginTop: "7%", float: "left" }}>
      <Button
        type="primary"
        className="session-form-button"
        onClick={(event) => {
          event.stopPropagation();
          showModal();
        }}
      >
        {t("Delete")}
      </Button>

      <Modal
        visible={visible}
        title={t("Are you sure you really want to delete this athlete ?")}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
      ></Modal>
    </div>
  );
}

export default withTranslation()(PopupAthleteDelete);
