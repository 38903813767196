import React, { useState } from "react";
import { Form, Select, Button, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { displayedIndicators } from "../utils/indicatorTranslate";
import { translate } from "../utils/indicatorTranslate";
import "./PopupEdit.css";

function PopupIndicatorAdd(props) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const Option = Select.Option;

  const { t, onSuccess } = props;
  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };
  const onSubmit = (form_data) => {
    onSuccess(form_data.indicator_selected);

    setTimeout(() => {
      form.setFieldsValue({ indicator_selected: undefined });
      setVisible(false);
    }, 1000);
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setVisible(true);
  };

  const newList = displayedIndicators.filter((indicator) => {
    const currentIndicators = JSON.parse(localStorage.getItem("indicators"));
    return !currentIndicators.includes(indicator);
  });

  const isMobile = window.innerWidth < 700;

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        className="session-form-button"
        onClick={() => {
          showModal();
        }}
      >
        {t("Add indicators")}
      </Button>

      <Modal
        className="athlete-modal"
        onCancel={handleCancel}
        visible={visible}
        cancelText={t("Cancel")}
        title={t("Select indicators")}
        footer={null}
      >
        <Form
          name="session_form"
          initialValues={{ indicator_selected: [] }}
          onFinish={onSubmit}
          form={form}
          onClick={(e) => e.stopPropagation()}
          onFinishFailed={onSubmitFailed}
          className="session_form"
          layout="vertical"
        >
          <Form.Item
            name="indicator_selected"
            label={t("Indicators")}
            className="session_input_text"
            rules={[{ required: true, message: t("Please Select Indicator") }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t("Select indicators")}
            >
              {newList
                .filter((indicator) => {
                  return !JSON.parse(
                    localStorage.getItem("indicators")
                  ).includes(indicator);
                })
                .map((displayed) => (
                  <Option value={displayed}>{translate[displayed]}</Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="session-form-button"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default withTranslation()(PopupIndicatorAdd);
