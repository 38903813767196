import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spin, Modal } from "antd";
import PopupAthletesSelection from "../components/PopupAthletesSelection";
import Subject from "../services/subject";
import "./BluetoothSendData.css";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { useRef } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import BlueToothService from "../services/bluetooth.service";
import {
  bleNusServiceUUID,
  bleNusCharRXUUID,
  bleNusCharTXUUID,
  MTU,
} from "../utils/bluetooth.util";
import { DownloadOutlined } from "@ant-design/icons";

var arrayValues = [];
let inProgress = new Subject();
var bletmp = {};
let tmp = 0;
let loadingTmp = 0;
let sessionSize = 1;
var nusService;
var rxCharacteristic;
var txCharacteristic;
let timestamp = 0;
let hasEnded = false;
let countVal = 0;
let athleteCount = 1;

const BluetoothSendData = (props) => {
  const [showScanButton, setshowScanButton] = useState(false);
  const [isSending, setisSending] = useState(false);
  const [connectButton, setConnectButton] = useState(false);
  const [showAthletesButton, setShowAthletesButton] = useState(true);
  const [showPlatform, setShowPlatform] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(loadingTmp);
  const [bleDevices, setBleDevices] = useState([]);
  const [started, setHasStarted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [user, setUser] = useState(null);

  let loads = useRef(0);

  const { id } = useParams();

  const navigate = useNavigate();

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        console.log(response.data);
        if (
          response.data.roles.filter((obj) => obj.name === "athlete").length !==
          0
        )
          axios
            .get(
              `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/athlete/${id}`,
              { headers: { "x-access-token": accessToken } }
            )
            .then((res) => {
              setUser({
                athleteName: response.data.name,
                tracker: res.data.name,
              });
            });
      });
  }, []);

  const isMobile = window.innerWidth < 800;

  console.log(user);

  var chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

  var isAndroid = navigator.userAgent.match(/Android/i);
  var isIos =
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i);
  var isSafariIos = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  var isOperaIos = navigator.userAgent.match(/OPiOS/i);

  var enabled = true;

  if (
    (window.innerWidth > 800 && !chromeAgent) ||
    (isAndroid && !chromeAgent) ||
    (isIos && isSafariIos) ||
    (isIos && isOperaIos)
  )
    enabled = false;

  const onAthleteSelected = (athletesList) => {
    setAthletes(athletesList);
    setShowAthletesButton(false);
    setshowScanButton(true);
  };

  const scanDevicesList = () => {
    if (!enabled) {
      setVisible(true);
      return;
    }
    if (athletes.length !== 0) {
      BlueToothService.chooseDevice({
        filters: athletes.map((t) => ({ name: t })),
        optionalServices: [bleNusServiceUUID],
      }).then((device) => {
        setAthletes((prevAthletes) =>
          prevAthletes.filter((athlete) => athlete !== device.name)
        );
        setBleDevices((old) => [...old, device]);
        if (athletes.length === 1) {
          setshowScanButton(false);
          setConnectButton(true);
        }
      });
    }
  };

  const scanAthleteDevice = () => {
    if (!enabled) {
      setVisible(true);
      return;
    }

    navigator.bluetooth
      .requestDevice({
        filters: [
          {
            name: user.tracker,
          },
        ],
        optionalServices: [bleNusServiceUUID],
      })
      .then((device) => {
        setBleDevices((old) => [...old, device]);
        setshowScanButton(false);
        setConnectButton(true);
      });
  };

  const handleOneDevice = async (device) => {
    bletmp = device;
    loads.current = 0;
    arrayValues = [];
    countVal = 0;
    hasEnded = false;
    sessionSize = 1;
    loadingTmp = 0;

    return new Promise((resolve) => {
      const server = bletmp.gatt.connect();
      console.log("Resolving " + device.name);
      resolve(server);
    })
      .then((serv) => {
        return serv.getPrimaryService(bleNusServiceUUID);
      })
      .then((service) => {
        nusService = service;

        return service.getCharacteristic(bleNusCharRXUUID);
      })
      .then((characteristic) => {
        rxCharacteristic = characteristic;
        console.log("Found RX characteristic");

        console.log("Locate TX characteristic");
        return nusService.getCharacteristic(bleNusCharTXUUID);
      })
      .then((characteristic) => {
        txCharacteristic = characteristic;
        console.log("Found TX characteristic");
        console.log("Enable notifications");
        return txCharacteristic.startNotifications();
      })
      .then(() => {
        console.log("Characteritics");
        console.log("Started notification on " + device.id);
        console.log("Notifications started");
        return txCharacteristic.addEventListener(
          "characteristicvaluechanged",
          handleNotifications
        );
      })
      .then(() => {
        return new Promise((resolve) => {
          console.log(`First attempt for ${device.id}`);
          const whenReady = () => {
            setisSending(true);
            setHasStarted(true);
            console.log(`Doing ${device.id}`);
            inProgress.subscribe(
              () => {
                inProgress.unsubscribe();
                inProgress.subscribe(
                  () => {
                    inProgress.unsubscribe();
                    inProgress.subscribe(
                      () => {
                        inProgress.unsubscribe();
                        console.log(`Ending ${device.id}`);

                        resolve();
                      },
                      () => {
                        console.log(`ERROR on doing device ${device.id}`);
                        inProgress.unsubscribe();
                        resolve(); // resolve, to go to next device
                      }
                    );

                    nusSendString(
                      "\x07\x00\x09\x00\x00\x00\x00\xFF\xFF\xFF\xFF\x04"
                    );
                  },
                  () => {
                    console.log(`ERROR on doing device ${device.id}`);
                    inProgress.unsubscribe();
                    resolve(); // resolve, to go to next device
                  }
                );
                setTimeout(() => {
                  nusSendString("\x05\x00\x00");
                }, 2000);
              },
              () => {
                console.log(`ERROR on doing device ${device.id}`);
                inProgress.unsubscribe();
                resolve(); // resolve, to go to next device
              }
            );
            nusSendString(
              "\x01\x00\x10\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0A\x0B\x0C\x0D\x0E\x0F\x10"
            );
          };
          whenReady();
        });
      })
      .catch((e) => alert(JSON.stringify(e)));
  };

  const forEachSeries = async () => {
    setConnectButton(false);
    for (let i = 0; i < bleDevices.length; i++) {
      loads.current = 0;
      loadingTmp = 0;
      athleteCount = i + 1;
      setLoadingProgress(0);
      await handleOneDevice(bleDevices[i]);
    }
    if (user === null) window.location.reload();
    else navigate(`/${id}/multiple-tracker-records`);
  };

  function createGroups(arr, numGroups) {
    const perGroup = Math.ceil(arr.length / numGroups);
    return new Array(numGroups)
      .fill("")
      .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
  }

  const axiosCall = (sub, isAthl) => {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}api/v1/record/file`,
      {
        arrayValues: sub,
        trackerName: bletmp.name,
        isAthlete: isAthl,
      },
      { headers: { "x-access-token": accessToken } }
    );
  };

  const handleNotifications = async (e) => {
    var value = new Uint8Array(e.target.value.buffer);
    if (tmp === -1) {
      return; // not tmp++
    } else if (tmp === 0) {
      inProgress.next();
    } else if (tmp === 1) {
      // '1234' => 1*2^24 + 2 * 2^16 + 3 * 2^8 + 4
      sessionSize = Number(
        (value[3] << 24) | (value[4] << 16) | (value[5] << 8) | value[6]
      );
      inProgress.next();
    } else {
      value = value.toString();
      value = value.slice(1);

      value = value.split(",");

      countVal = countVal + 4;

      const isFinalRequired = countVal >= sessionSize - 4 || hasEnded;

      const VALUES_TS_A = ["0", "1", "3", "4", "5", "6", "7", "8"];
      const VALUES_TS_B = ["1", "3", "4", "5", "6", "7", "8"];
      const isTSRequired =
        VALUES_TS_A.includes(value[8]) ||
        VALUES_TS_A.includes(value[60]) ||
        VALUES_TS_B.includes(value[112]) ||
        VALUES_TS_B.includes(value[164]);

      const VALUES_END_PARTIAL = ["22", "23", "24", "25", "26", "27"];
      const isPartialRequired =
        [value[9], value[61], value[113], value[165]].filter(
          (v) => !VALUES_END_PARTIAL.includes(v)
        ).length === 0;

      if (isFinalRequired) {
        console.log("SIZE : ", sessionSize);
        hasEnded = true;
        countVal = sessionSize;
        tmp = -1;
        arrayValues.push({
          value: [],
          timestamp: 0,
          hasEnded: hasEnded,
          trackerName: bletmp.name,
          sessionSize: sessionSize,
        });
        hasEnded = "lol";
        const chunkedArray = createGroups(arrayValues, 60);
        if (user !== null) {
          setisSending(false);
          const promise = chunkedArray.reduce(
            (previous, subArray) =>
              previous
                .then(() => axiosCall(subArray, true))
                .then((res) => console.log(res))
                .catch((err) => console.log(err)),
            Promise.resolve()
          );
          await promise.then(() => {
            if (process.env.INSTANCE === "prod") nusSendString("\x0B\x00\x00");
            setisSending(true);
            inProgress.next();
          });
        } else {
          const chunkedArray = createGroups(arrayValues, 60);
          setisSending(false);
          const promise = chunkedArray.reduce(
            (previous, subArray) =>
              previous.then(() =>
                axiosCall(subArray, false)
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err))
              ),
            Promise.resolve()
          );
          await promise.then(() => {
            if (process.env.INSTANCE === "prod") nusSendString("\x0B\x00\x00");
            inProgress.next();
          });
        }
      } else if (isTSRequired) {
        timestamp = Date.now();
      } else if (isPartialRequired) {
        arrayValues.push({
          value: value,
          timestamp: timestamp,
          hasEnded: hasEnded,
          trackerName: bletmp.name,
          sessionSize: sessionSize,
        });
      }
      loads.current = loads.current + 400 / sessionSize;
      if (Math.floor(loads.current - loadingTmp) > 0) {
        loadingTmp = Math.round(loads.current * 10) / 10;
        setLoadingProgress(loadingTmp);
      }
    }
    tmp++;
  };

  const nusSendString = (s) => {
    if (bletmp && bletmp.gatt.connected) {
      let val_arr = new Uint8Array(s.length);
      for (let i = 0; i < s.length; i++) {
        let val = s[i].charCodeAt(0);
        val_arr[i] = val;
      }
      sendNextChunk(val_arr);
    } else {
      console.log("Not connected to a device yet.");
    }
  };

  const sendNextChunk = (a) => {
    let chunk = a.slice(0, MTU);
    rxCharacteristic.writeValue(chunk).then(function () {
      if (a.length > MTU) {
        sendNextChunk(a.slice(MTU));
      } else return;
    });
  };

  const handleCancel = () => {
    setConnectButton(false);
  };

  const { t } = props;

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const PopupAthleteSelectionMemo = React.memo(() => (
    <PopupAthletesSelection onSuccess={onAthleteSelected} />
  ));

  return (
    <>
      {user === null ? (
        <></>
      ) : connectButton || isSending ? (
        <Modal
          className="athlete-modal"
          visible={connectButton}
          onCancel={handleCancel}
          title={t("Confirm Download")}
          footer={null}
        >
          <Button
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flex: "1",
              height: "44%",
              whiteSpace: "inherit",
            }}
            type="primary"
            htmlType="submit"
            className="session-form-button"
            onClick={() => forEachSeries()}
          >
            {t("Start Download")}
          </Button>
          {isSending ? (
            <>
              <div className="transferContainer">
                {sessionSize === 0 ? (
                  <>
                    <p className="tranfer">{t("Tracker Empty")}</p>
                  </>
                ) : (
                  <>
                    <p className="finishedBlink">
                      {t("Don't switch app during process.")}
                    </p>

                    <p className="tranfer">
                      {t("Transfer:")} {t("Athlete")} : {athleteCount}/
                      {bleDevices.length} - {loadingProgress} %
                      {/*<Spin tip={t("Loading ...")}></Spin>*/}
                    </p>

                    <img
                      className="logoYellow"
                      alt="mclloyd"
                      src={require("../assets/fire-yellow.png")}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        <></>
      )}
      {/*{!visible ? (
        <></>
      ) : (
        <Modal
          className="athlete-modal"
          visible={visible}
          onCancel={handleCancel}
          title={t("Please use Bluefy mobile app or PC chrome browser.")}
          footer={null}
        ></Modal>
      )}/*}
      {/*{!hasDownloaded ? (
        <></>
      ) : (
        <Modal
          className="athlete-modal"
          visible={hasDownloaded}
          title={t("Download has terminated")}
          onCancel={handleCancel}
          cancelText={t("Cancel")}
          footer={null}
        >
          <Form
            name="session_form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onClick={(e) => {}}
            onFinishFailed={onSubmitFailed}
            className="session_form"
            layout="vertical"
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
              >
                {t("Refresh Page")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}*/}
      <div className="mainContainerBluetooth">
        <>
          {" "}
          {showAthletesButton ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {athletes.length !== 0 ? null : user === null ? (
                <>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    {t("Select athletes to download records from")}
                  </p>
                  <PopupAthleteSelectionMemo />
                </>
              ) : !connectButton && !isSending ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: "1",
                      height: "44%",
                      whiteSpace: "inherit",
                    }}
                    type="primary"
                    htmlType="submit"
                    className="session-form-button"
                    onClick={() => scanAthleteDevice()}
                  >
                    <DownloadOutlined style={{ color: "#d5bb64", flex: "1" }} />

                    <p
                      style={{
                        flex: "4",
                        marginBottom: "0",
                        marginLeft: "3%",
                        fontSize: !isMobile ? "small" : "11px",
                        lineHeight: "1.1",
                      }}
                    >
                      {t("Pair with bluetooth")}
                    </p>
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: "2",
                      lineHeight: "0.9",
                      marginLeft: "3%",
                    }}
                  >
                    <img
                      style={{
                        flex: 1,
                        maxWidth: "100px",
                        maxHeight: "100px",
                        margin: "auto",
                      }}
                      alt="green slow"
                      src={require("../assets/picto-green-slow.png")}
                    />
                    <div
                      style={{
                        flex: "4",
                        alignItems: "center",
                        margin: "auto",
                      }}
                    >
                      <p
                        style={{
                          marginTop: "6%",
                          marginBottom: "0",
                          marginLeft: "3%",
                          fontSize: !isMobile ? "small" : "11px",
                          color: "white",
                        }}
                      >
                        {t("Athlete")} :{" "}
                        <span style={{ color: "#d5bb64" }}>
                          {user.athleteName}
                        </span>
                      </p>
                      <p
                        style={{
                          flex: "4",
                          marginBottom: "0",
                          marginLeft: "3%",
                          fontSize: !isMobile ? "small" : "11px",
                          color: "white",
                        }}
                      >
                        {t("Tracker")} ID : {user.tracker}
                      </p>
                      <p
                        style={{
                          flex: "4",
                          marginTop: "6%",
                          marginLeft: "3%",
                          fontSize: !isMobile ? "small" : "11px",
                          color: "#d5bb64",
                        }}
                      >
                        {t("Make sure to turn the tracker(s) on")} (
                        <span style={{ color: "#66FF99" }}>
                          {t("green flash")}
                        </span>
                        ){t("before pairing.")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <></>
          )}
          {isSending ? (
            <p className="trackerMessage" style={{ textAlign: "center" }}>
              {t("TRANSFERING DATA TO MCLLOYD PLATFORM")}
            </p>
          ) : showPlatform && !isAndroid ? (
            <p className="trackerMessage" style={{ textAlign: "center" }}>
              {t("TRANSFER SUCCESFULLY COMPLETED")}
            </p>
          ) : (
            <></>
          )}
          {showScanButton ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "5%",
              }}
            >
              <Button
                type="primary"
                className="login-form-button"
                style={{ flex: 1 }}
                onClick={() => scanDevicesList()}
              >
                {t("Pair selected trackers")}
              </Button>
              <div style={{ flex: "5" }}></div>
            </div>
          ) : (
            <></>
          )}
          {connectButton ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "5%",
              }}
            >
              <Button
                type="primary"
                style={{ flex: 1 }}
                className="session_form"
                onClick={() => forEachSeries()}
              >
                {t("Start Downloading")}
              </Button>
              <div style={{ flex: "5" }}></div>
            </div>
          ) : (
            <></>
          )}
          {isSending ? (
            <>
              <div className="transferContainer">
                {sessionSize === 0 ? (
                  <>
                    <p className="tranfer">{t("Tracker Empty")}</p>
                  </>
                ) : (
                  <>
                    <p className="finishedBlink">
                      {t("Don't switch app during process.")}
                    </p>
                    <p className="tranfer">
                      {t("Transfer:")} {t("Athlete")} : {athleteCount}/
                      {bleDevices.length} - {loadingProgress} %
                      {/*<Spin tip={t("Loading ...")}></Spin>*/}
                    </p>

                    <img
                      className="logoYellow"
                      alt="mclloyd"
                      src={require("../assets/fire-yellow.png")}
                    />
                  </>
                )}
              </div>
            </>
          ) : started ? (
            <>
              <p className="finishedBlink">
                {t("Don't switch app during process.")}
              </p>
              <p className="tranfer">
                {t("Transfer:")} {t("Athlete")} : {athleteCount}/
                {bleDevices.length} - {loadingProgress} %
                {/*<Spin tip={t("Loading ...")}></Spin>*/}
              </p>
              <div style={{ textAlign: "center" }}>
                <InfoCircleOutlined
                  style={{ color: "white", fontSize: "20px" }}
                />
                <span
                  style={{
                    color: "white",
                    fontSize: "large",
                    marginLeft: "3%",
                  }}
                >
                  {t("Your")}{" "}
                  {user === null ? t("records and sessions") : t("records")}{" "}
                  {t("are being created. Please wait.")}
                </span>
              </div>
            </>
          ) : null}
        </>
      </div>
    </>
  );
};

export default withTranslation()(BluetoothSendData);
