import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  DatePicker,
  InputNumber,
  Modal,
  message,
} from "antd";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";
import moment from "moment";
import { useParams } from "react-router-dom";

function PopupAthleteCreate(props) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [trackers, setTrackers] = useState([]);

  const { id } = useParams();

  const coachId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    console.log(coachId);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/coach/${coachId}`,
        { headers: { "x-access-token": accessToken } }
      )
      .then((response) => {
        setTrackers(response.data);
      });
    setIsLoading(false);
  }, []);

  const onSubmit = (form_data) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}api/v1/users/`,

        {
          firstName: form_data.firstName,
          lastName: form_data.lastName,
          name: form_data.name,
          color: form_data.color,
          gender: form_data.gender,
          birthdate: form_data.birthdate._d,
          height: Number(form_data.height),
          weight: Number(form_data.weight),
          email: form_data.email,
          password: form_data.password,
          vmax: Number(form_data.vmax),
          amax: Number(form_data.amax),
          fcmax: Number(form_data.fcmax),
          fclow: Number(form_data.fclow),
          vma: Number(form_data.vma),
          vo2max: Number(form_data.vo2max),
          power_event_thresold: Number(form_data.power_event_thresold),
          capability: Number(form_data.capability),
          role_ids: [5],
          athlete_ids: [],
          coachId: coachId,
        },
        { headers: { "x-access-token": accessToken } }
      )
      .then(
        (res) => {
          console.log("Success create athlete");
          setVisible(false);
          window.location.reload();
        },
        (error) => {
          const resMessage =
            error.response?.data?.message || error.message || error.toString();
          message.error(resMessage);
          setLoading(false);
        }
      )
      .catch((error) => console.log(error));
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {};

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div>
      <Button
        type="primary"
        style={{ width: "100%" }}
        onClick={(event) => {
          event.stopPropagation();
          showModal();
        }}
      >
        {t("Add")}
      </Button>
      <div id="athlete-modal">
        <Modal
          className="athlete-modal"
          visible={visible}
          title={<span className="titleEdit">{t("Add an athlete")}</span>}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={t("Cancel")}
          footer={null}
        >
          {isLoading ? <Spin tip={t("Loading ...")}></Spin> : <></>}
          <Form
            name="session_form"
            initialValues={{ remember: true }}
            form={form}
            onFinish={onSubmit}
            onClick={(e) => e.stopPropagation()}
            onFinishFailed={onSubmitFailed}
            className="session_form"
            layout="vertical"
          >
            <Form.Item
              name="firstName"
              label={t("First Name")}
              className="session_input_text"
            >
              <Input
                placeholder={t("First Name")}
                name="firstName"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={t("Last Name")}
              className="session_input_text"
            >
              <Input
                placeholder={t("Last Name")}
                name="lastName"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={t("Surname")}
              rules={[{ required: true, message: t("Please enter surname") }]}
              className="session_input_text"
            >
              <Input
                placeholder={t("Surname")}
                name="name"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label={t("Gender")}
              className="session_input_text"
            >
              <Input
                placeholder={t("Gender")}
                name="gender"
                disabled={loading}
                onChange={(value) => handleChange(value)}
              />
            </Form.Item>
            <Form.Item
              name="birthdate"
              label={t("Birth date")}
              rules={[
                { required: true, message: t("Please enter birth date") },
              ]}
              className="session_input_text"
            >
              <DatePicker
                placeholder={t("Select Date")}
                disabledDate={(current) => moment() <= current}
                onChange={(value) => handleChange(value)}
              />
            </Form.Item>
            <Form.Item
              name="height"
              label={t("Height")}
              rules={[
                { required: true, message: t("Please enter height") },
                {
                  pattern: new RegExp(/^\d*\.?\d*$/),

                  message: t("Only numbers allowed"),
                },
                {
                  validator: (_, height) => {
                    if (height >= 0 && height <= 500) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(t("Must be between 0 and 500"));
                    }
                  },
                },
              ]}
              className="session_input_text"
            >
              <Input
                placeholder={t("Height")}
                name="height"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="weight"
              label={t("Weight")}
              rules={[
                { required: true, message: t("Please enter weight") },
                {
                  pattern: new RegExp(/^\d*\.?\d*$/),

                  message: t("Only numbers allowed"),
                },
                {
                  validator: (_, weight) => {
                    if (weight >= 0 && weight <= 500) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(t("Must be between 0 et 500"));
                    }
                  },
                },
              ]}
              className="session_input_text"
            >
              <Input
                placeholder={t("Weight")}
                name="weight"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={t("Email")}
              rules={[{ required: true, message: t("Please enter email") }]}
              className="session_input_text"
            >
              <Input
                style={{ backgroundColor: "black" }}
                name="email"
                autoComplete="new-email"
                placeholder="Email"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("Password")}
              rules={[{ required: true, message: t("Please Enter Password") }]}
              className="login_input_text"
              initialValue={""}
            >
              <Input.Password
                disabled={loading}
                placeholder={t("Password")}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="vmax"
              label="Vmax (km/h)"
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder="Vmax (km/h)"
                name="vmax"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="amax"
              label="Amax (m/s2)"
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder="Amax (m/s2)"
                name="amax"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="fcmax"
              label="FCmax (bpm)"
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder="FCmax (bpm)"
                name="fcmax"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="fclow"
              label={t("FC Repos (bpm)")}
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                put
                placeholder={t("FC Repos (bpm)")}
                name="fclow"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="vma"
              label="VMA (km/h)"
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder="VMA (km/h)"
                name="vma"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="vo2max"
              label="VO2max (ml/min/kg)"
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder="VO2max (ml/min/kg)"
                name="vo2max"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="power_event_thresold"
              label={t("Power event thresold (Watts/kg)")}
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder={t("Power event thresold (Watts/kg)")}
                name="power_event_thresold"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="capability"
              label={t("Athletic capability")}
              className="session_input_text"
            >
              <InputNumber
                type="number"
                step={0.01}
                min={0}
                placeholder={t("Athletic capability")}
                name="capability"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="session-form-button"
              >
                {t("Save")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default withTranslation()(PopupAthleteCreate);
