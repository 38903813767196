import axios from "axios";

function chooseDevice(options) {
  return navigator.bluetooth.requestDevice(options);
}

function savePartial(value, trackerName, timestamp, sessionSize, hasEnded) {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}api/v1/record/file`, {
    value: value,
    trackerName: trackerName,
    timestamp: timestamp,
    sessionSize: sessionSize,
    hasEnded: hasEnded,
  });
}

function saveFinal(trackerName, sessionSize, hasEnded) {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}api/v1/record/file`, {
    hasEnded: hasEnded,
    trackerName: trackerName,
    sessionSize: sessionSize,
  });
}

export default {
  chooseDevice,
  savePartial,
  saveFinal,
};
