import * as React from "react";
import { withTranslation } from "react-i18next";
import { sessionToFormatedStartDate, sessionToFormatedEndDateTime } from "../../../services/date.service";
import { useNavigate, useParams } from "react-router-dom";

function RecordEndItemBySessionMultiple(props) {
  const {record} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div
      key={record.uuid}
      onClick={() =>
        navigate(`/${id}/indicatorsSession-multiple`, {
          state: {
            uuid: record.uuid,
            recordId: record.id,
            startTime: record.startTime,
            endTime: record.endTime,
            day: sessionToFormatedStartDate(record),
            trackerId: record.trackerName,
            user: record.athlete.name,
            indicators: record.indicators,
            record: record,
          },
        })
      }
      style={{ marginTop: "3%", marginBottom: "3%", cursor: "pointer" }}
    >
      {sessionToFormatedEndDateTime(record)}
    </div>
  );
}

export default withTranslation()(RecordEndItemBySessionMultiple);
