import React, { useState } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";

function PopupSessionDelete(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { session } = props;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);

    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}api/v1/sessions/${session.id}`,
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((response) => {
        console.log("Success delete element");
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t, isMobile } = props;

  return (
    <>
      {!isMobile ? (
        <>
          <DeleteOutlined
            style={{
              fontSize: "14px !important",
              color: "#d5bb64",
              flex: 1,
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "auto",
            }}
            onClick={(event) => {
              event.stopPropagation();
              showModal();
            }}
          />
        </>
      ) : (
        <Button
          style={{ marginLeft: "2%", color: "#d5bb64" }}
          onClick={() => {
            showModal();
          }}
        >
          {t("Delete")}
        </Button>
      )}
      <Modal
        visible={visible}
        title={t("Are you sure you really want to delete this record ?")}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={<span style={{ color: "#d5bb64" }}>{t("Cancel")}</span>}
      ></Modal>
    </>
  );
}

export default withTranslation()(PopupSessionDelete);
