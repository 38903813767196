import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Layout, Menu, Spin, Button, Upload, message } from "antd";
import "antd/dist/antd.css";
import ImgCrop from "antd-img-crop";
import {
  NodeIndexOutlined,
  SettingOutlined,
  AreaChartOutlined,
  TeamOutlined,
  SlidersOutlined,
  SaveOutlined,
  BulbOutlined,
  ReadOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { useProvideAuth } from "../services/auth.service";
import { withTranslation } from "react-i18next";
import { use } from "i18next";

const { Sider, Content, Header } = Layout;

const isMobile = window.innerWidth < 700;

let isAthlete;
let userName;

const PrivateLayout = (props) => {
  const auth = useProvideAuth();
  const navigate = useNavigate();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("noUrl");

  const { id } = useParams();

  console.log(id);

  const userId = id;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const fileToDataUri = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: require("../assets/coach.png"),
    },
  ]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        console.log("RES : ", response.data);
        isAthlete =
          response.data.roles.filter((obj) => obj.name === "athlete").length !==
          0;
        userName = response.data.name;
        if (response.data.photo) {
          console.log(response.data);
          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: response.data.photo,
            },
          ]);
          setProfilePhoto(response.data.photo);
        } else setProfilePhoto(require("../assets/coach.png"));
        setLoading(false);
      })
      .catch((err) => alert(err));
  }, []);

  /* useEffect(() => {
    if (fileList.length !== 0) {
      console.log(Object.entries(fileList[0]));
      axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`,

          { photo: fileList[0].thumbUrl },
          { headers: { "x-access-token": accessToken } }
        )
        .then((user) => {
          //console.log(newFileList[0]["thumbUrl"]);
          console.log(user);
          //window.location.reload();
        })
        .catch((err) => console.log(err));
    }
  }, [fileList]);*/

  const handleUpload = (event) => {
    console.log("UPLOAD: ", event);
    setFileList(event.fileList);
    const fileObj = event.fileList && event.fileList[0];
    if (!fileObj) {
      return;
    }

    fileToDataUri(fileObj).then((dataUri) => {
      console.log(dataUri);
      axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`,

          { photo: dataUri },
          { headers: { "x-access-token": accessToken } }
        )
        .then((user) => {
          //console.log(newFileList[0]["thumbUrl"]);
          console.log(user);
          //window.location.reload();
        })
        .catch((err) => console.log(err));
    });
  };
  const onPreview = async (file) => {};

  const { t } = props;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const getSettings = () => {
    if (!isAthlete)
      return [
        getItem(
          t("Trackers"),
          "h2",
          <Link to={`/${userId}/manage-trackers`}>
            {" "}
            <WifiOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
        getItem(
          t("Athletes"),
          "h3",
          <Link to={`/${userId}/manage-athletes`}>
            <TeamOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
        getItem(
          t("User Settings"),
          "h8",
          <Link to={`/${userId}/settings-units`}>
            <SlidersOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
      ];
    else
      return [
        getItem(
          t("User Settings"),
          "h8",
          <Link to={`/${userId}/settings-units`}>
            <SlidersOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
      ];
  };
  const items = [
    getItem(
      t("Data"),
      "data",
      <AreaChartOutlined style={{ color: "white", fontSize: "20px" }} />,
      [
        getItem(
          t("Sessions"),
          "g1",
          <Link to={localStorage.getItem("home")}>
            <NodeIndexOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
        getItem(
          t("Records"),
          "g2",
          <Link
            to={{
              pathname: `/${userId}/raw-sessions/`,
              state: { userId: userId },
            }}
          >
            <SaveOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
      ]
    ),
    getItem(
      t("Management"),
      "set",
      <SettingOutlined style={{ color: "white", fontSize: "20px" }} />,
      getSettings()
    ),
    getItem(
      t("Help"),
      "sub4",
      <BulbOutlined style={{ color: "white", fontSize: "20px" }} />,
      [
        getItem(
          t("Trackers"),
          "s1",
          <Link to={`/${userId}/details`}>
            <WifiOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
        getItem(
          t("Tutorials"),
          "s2",
          <Link to={`/${userId}/tutorials`}>
            <ReadOutlined style={{ color: "white", fontSize: "20px" }} />
          </Link>
        ),
      ]
    ),
  ];

  // render ---------------------------------------------------------
  return loading ? (
    <Spin tip={t("Loading...")}></Spin>
  ) : (
    <Layout>
      <Header style={{ textAlign: isMobile ? "center" : "left" }}>
        <img src={require("../assets/mclloyd-header.png")} alt="mclloyd" />
        <ImgCrop rotate>
          <Upload
            showUploadList={{ showPreviewIcon: false }}
            action="https://run.mocky.io/v3/f583d012-ad45-4fa3-ab52-367b483d90f9?mocky-delay=29000ms"
            headers={{
              authorization: "authorization-text",
              "Access-Control-Allow-Origin": "*",
            }}
            fileList={fileList}
            beforeUpload={(file) => {
              console.log("HELLO");
              const reader = new FileReader();

              reader.onload = (e) => console.log(e);
              const isJPG =
                file.type === "image/jpeg" || file.type === "image/png";
              if (!isJPG) {
                message.error("You can only upload JPG or PNG file!");
                return false;
              } else {
                return true;
              }
            }}
            listType="picture-card"
            onChange={(event) => handleUpload(event)}
            onPreview={onPreview}
          >
            {fileList.length < 1 && "+ Upload"}
          </Upload>
        </ImgCrop>
        {/* <label htmlFor="image">
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleUpload}
            style={{ display: "none" }}
          />
          <img
            id="img"
            style={{
              flex: "1",
              cursor: "pointer",
              borderRadius: "50%",
              overflow: "hidden",
              width: "auto",
              height: "auto",
              maxWidth: "190px",
              maxHeight: "50px",
              marginLeft: !isMobile ? "40%" : "0%",
            }}
            alt="profile"
            src={profilePhoto}
          />{" "}
          </label>*/}
        <p
          style={{
            color: "white",
            flex: "4",
            marginLeft: isMobile ? "4%" : "0%",
            marginBottom: "0",
            display: isMobile ? "none" : "block",
          }}
        >
          {userName}
        </p>
        <Button
          type="primary"
          style={{
            marginRight: !isMobile ? "5%" : "9%",
            flex: "0",
            marginLeft: isMobile
              ? localStorage.getItem("keyLang") === "en"
                ? "12%"
                : "4%"
              : "0%",
          }}
          onClick={() => {
            auth.logout();
            navigate("/");
          }}
        >
          {t("Logout")}
        </Button>
      </Header>
      <Layout className="private_layout">
        <Sider
          style={{ minHeight: "100vh" }}
          width={200}
          className="site-layout-background"
          trigger={null}
          collapsible
          breakpoint="sm"
          collapsedWidth={60}
        >
          <Menu
            mode="inline"
            style={{
              height: "100%",
              borderRight: 0,
              backgroundColor: "black",
            }}
            items={items}
          >
            {/*} <Menu.Item
                key="0"
                icon={<HomeOutlined />}
                label="Données"
                children={
                  <Menu.Item key="1" icon={<UserOutlined />} label="Sessions">
                    <Tooltip title={t("Home page")}>
                      <Link to={localStorage.getItem("home")}></Link>
                    </Tooltip>
                  </Menu.Item>
                }
              />

              <Menu.Item
                key="01"
                icon={<HomeOutlined type="message" theme="outlined" />}
              >
                <Tooltip title={t("Home page")}>
                  <Link to={localStorage.getItem("home")}></Link>
                </Tooltip>
              </Menu.Item>
              <Menu.Item
                style={{ height: "40px", paddingLeft: "0px !important" }}
                key="1"
                icon={
                  <ArrowDownOutlined
                    type="message"
                    style={{
                      marginRight: "auto",
                      marginLeft: "-2px",
                      paddingLeft: "0px !important",
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#000",
                    }}
                    theme="outlined"
                  />
                }
              >
                <Tooltip title={t("Download page")}>
                  <Link to="/download"></Link>
                </Tooltip>
              </Menu.Item>
              {JSON.parse(localStorage.getItem("trackers")).length > 1 ? (
                <>
                  <Menu.Item
                    key="18"
                    style={{ height: "40px", paddingLeft: "0px !important" }}
                    icon={
                      <FormOutlined
                        type="message"
                        style={{
                          marginRight: "auto",
                          marginLeft: "-2px",
                          paddingLeft: "0px !important",
                          textAlign: "center",
                          fontSize: "20px",
                          color: "#000",
                        }}
                        theme="outlined"
                      />
                    }
                  >
                    <Tooltip title="Athletes">
                      <Link to="/manage-athletes"></Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item
                    key="38"
                    style={{ height: "40px", paddingLeft: "0px !important" }}
                    icon={
                      <SettingOutlined
                        type="message"
                        style={{
                          marginRight: "auto",
                          marginLeft: "-2px",
                          paddingLeft: "0px !important",
                          textAlign: "center",
                          fontSize: "20px",
                          color: "#000",
                        }}
                        theme="outlined"
                      />
                    }
                  >
                    <Tooltip title="Trackers">
                      <Link to="/manage-trackers"></Link>
                    </Tooltip>
                  </Menu.Item>
                </>
              ) : (
                <></>
              )}
              {JSON.parse(localStorage.getItem("trackers")).length > 1 ? (
                <Menu.Item
                  key="24"
                  style={{ height: "40px", paddingLeft: "0px !important" }}
                  icon={
                    <ClockCircleOutlined
                      type="message"
                      style={{
                        marginRight: "auto",
                        marginLeft: "-2px",
                        paddingLeft: "0px !important",
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#000",
                      }}
                      theme="outlined"
                    />
                  }
                >
                  <Tooltip title="Raw sessions">
                    <Link to="/raw-sessions"></Link>
                  </Tooltip>
                </Menu.Item>
              ) : (
                <></>
              )}
              <Menu.Item
                key="2"
                style={{ height: "40px", paddingLeft: "0px !important" }}
                icon={
                  <InfoCircleOutlined
                    type="message"
                    style={{
                      marginRight: "auto",
                      marginLeft: "-2px",
                      paddingLeft: "0px !important",
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#000",
                    }}
                    theme="outlined"
                  />
                }
              >
                <Tooltip title={t("Infos page")}>
                  <Link to="/details"></Link>
                </Tooltip>
              </Menu.Item>
              <Menu.Item
                key="12"
                style={{ height: "40px", paddingLeft: "0px !important" }}
                icon={
                  <SettingOutlined
                    type="message"
                    style={{
                      marginRight: "auto",
                      marginLeft: "-2px",
                      paddingLeft: "0px !important",
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#000",
                    }}
                    theme="outlined"
                  />
                }
              >
                <Tooltip title={t("Settings Units")}>
                  <Link to="/settings-units"></Link>
                </Tooltip>
              </Menu.Item>
              <Menu.Item
                key="13"
                style={{ height: "40px", paddingLeft: "0px !important" }}
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}
                icon={
                  <LogoutOutlined
                    type="message"
                    style={{
                      marginRight: "auto",
                      marginLeft: "-2px",
                      paddingLeft: "0px !important",
                      textAlign: "center",
                      fontSize: "20px",

                      color: "red",
                    }}
                    theme="outlined"
                  />
                }
              >
                <Tooltip title={t("Logout")}>
                  <Link to="/"></Link>
                </Tooltip>
              </Menu.Item>*/}
          </Menu>
        </Sider>
        <Layout
          id="page_wrapper"
          style={{ lineHeight: "2" }}
          className="layout"
        >
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default withTranslation()(PrivateLayout);
