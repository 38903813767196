export default class Subject {
    constructor() {
        this._callback = null;
        this._callbackNOK = null;
    }

    next() {
        if (this._callback) {
            setTimeout(() => {
                this._callback();
            })
        }
    }

    crash() {
        if (this._callbackNOK) {
            setTimeout(() => {
                this._callbackNOK();
            })
        }
    }

    subscribe(callback, callbackNOK) {
        this._callback = callback;
        this._callbackNOK = callbackNOK;
    }

    unsubscribe() {
        this._callback = null;
        this.callbackNOK = null;
    }
}
