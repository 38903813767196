import React, { useEffect, useRef, useLayoutEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Spin } from "antd";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import "./Graph.css";

const displayedIndicators = [
    "temps.duree_seance",
    "course.D",
    "course.d_sprintee",
    "course.m_min",
    "course.V_max",
    "sprint.nb_sprint",
    "sprint.duree_moy",
    "sprint.dist_moy",
    "sprint.v_moy",
    "sprint.duree_max",
    "sprint.dist_max",
    "sprint.v_max",
    "acc.acc_max_liste",
    "acc.dec_max_liste",
    "acc.nb_acc_liste",
    "acc.nb_dec_liste",
    "acc.acc_moy_liste",
    "acc.dec_moy_liste",
    "acc.dist_moy_suite_acc_liste",
    "acc.dist_max_suite_acc_liste",
    "dir.nb_dir_liste",
    "choc.nb_chocs",
    "choc.choc_max",
    "pas.nb_pas",
    "pas.dg_pas_moy",
    "meta_cardio.EPOC_max",
    "autre.qsignal_moy",
    "temps.duree_data",
    "temps.duree_gps",
    "course.V_moy",
    "power.nb_event",
    "power.dur_moy",
    "power.p_moy",
    "power.temps_moy_entre",
    "dp.puissance_moy_aerobie",
    "dp.puissance_max",
    "autre.hdop_max",
    "autre.hdop_min",
    "autre.hdop_moy",
    "ffr.dist_en_acceleration",
    "ffr.dist_en_deceleration",
    "ffr.acceleration_max",
    "ffr.deceleration_max",
    "player_load.cumul_val",
    "dp.distance_eq",
    "rhie.nb_rhie",
];

const translate = {
    "temps.duree_seance": "Record Duration",
    "course.D": "Total distance",
    "course.d_sprintee": "Sprints total distance",
    "course.m_min": "Tempo (m/min)",
    "course.V_max": "Max speed",
    "sprint.nb_sprint": "Number of sprints",
    "sprint.duree_moy": "Average duration of sprints",
    "sprint.dist_moy": "Average distance of sprints",
    "sprint.v_moy": "Average speed of sprints",
    "sprint.duree_max": "Max. duration of a sprint",
    "sprint.dist_max": "Max. distance of a sprint",
    "sprint.v_max": "Max. speed of a sprint",
    "acc.acc_max_liste": "Max. acceleration (m/s2)",
    "acc.dec_max_liste": "Max. deceleration (m/s2)",
    "acc.nb_acc_liste": "Number of accelerations",
    "acc.nb_dec_liste": "Number of decelerations",
    "acc.acc_moy_liste": "Average acceleration (m/s2)",
    "acc.dec_moy_liste": "Average deceleration (m/s2)",
    "acc.dist_moy_suite_acc_liste": "Average distance of accelerations",
    "acc.dist_max_suite_acc_liste": "Average distance of decelerations",
    "dir.nb_dir_liste": "Number of changes of directions",
    "choc.nb_chocs": "Number of collisions",
    "choc.choc_max": "Max. collision (G)",
    "pas.nb_pas": "Number of steps",
    "pas.dg_pas_moy": "Average right-left unbalance",
    "meta_cardio.EPOC_max": "Max. EPOC",
    "autre.qsignal_moy": "GPS-Average quality of signal",
    "temps.duree_data": "Data duration",
    "temps.duree_gps": "GPS duration",
    "course.V_moy": "Average speed",
    "power.nb_event": "Number of power events",
    "power.dur_moy": "Average duration of power events",
    "power.p_moy": "Average power of power events (W)",
    "power.temps_moy_entre": "Av. duration between 2 power events",
    "dp.puissance_moy_aerobie": "Aerobic average power (W)",
    "dp.puissance_max": "Di Prampero max power (W)",
    "autre.hdop_max": "Max. HPOP",
    "autre.hdop_min": "Min. HDOP",
    "autre.hdop_moy": "Average HDOP",
    "ffr.dist_en_acceleration": "Distance of accelerations",
    "ffr.dist_en_deceleration": "Distance of decelerations",
    "ffr.acceleration_max": "Max. acceleration (m/s2)",
    "ffr.deceleration_max": "Max. deceleration (m/s2)",
    "player_load.cumul_val": "Total player load (G)",
    "dp.distance_eq": "Di prampero equivalent distance",
    "rhie.nb_rhie": "Number of RHIE",
};

const Graph = (props) => {
    const location = useLocation();
    const [fullIndicators, setFullIndicators] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedIndicator, setSelectedIndicator] = useState("Select");
    const [chart, setChart] = useState({
        options: {
            chart: {
                id: "apex-indicators",
                color: "red",
            },
            xaxis: {
                categories: [],
            },
        },
        series: [
            {
                name: "value",
                data: [],
            },
        ],
    });

    var localUnitFactorDistance = localStorage.getItem("distanceUnitFactor");
    var localUnitFactorSpeed = localStorage.getItem("speedUnitFactor");

    if (localUnitFactorDistance === null) localUnitFactorDistance = "m";
    if (localUnitFactorSpeed === null) localUnitFactorSpeed = "km/h";

    let factorSpeed;
    let factorDistance;

    localUnitFactorDistance === "miles"
        ? (factorDistance = 0.000621371)
        : localUnitFactorDistance === "yards"
            ? (factorDistance = 1.09361)
            : localUnitFactorDistance === "feets"
                ? (factorDistance = 3.28084)
                : (factorDistance = 1);
    localUnitFactorSpeed === "m/s"
        ? (factorSpeed = 0.277778)
        : localUnitFactorSpeed === "mph"
            ? (factorSpeed = 0.621371)
            : (factorSpeed = 1);
    console.log(localUnitFactorSpeed);
    console.log(localUnitFactorDistance);
    const distanceKeys = [
        "Total distance",
        "Sprints total distance",
        "Average distance of sprints",
        "Max. distance of a sprint",
        "Average distance of decelerations",
        "Average distance of accelerations",
        "Distance of accelerations",
        "Distance of decelerations",
        "Di prampero equivalent distance",
    ];
    const speedKeys = [
        "Max speed",
        "Average speed of sprints",
        "Max. speed of a sprint",
        "Average speed",
    ];

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        console.log(fullIndicators);
    });

    const handleSelectIndicator = (event) => {
        console.log(event.target.value);
        setSelectedIndicator(event.target.value);
    };

    const { t } = props;

    const fetchMyAPI = useCallback(async () => {
        const session = location.state.session;
        console.log(session);
        axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}api/v1/indicator/${session.uuid}`
            )
            .then((response) => {
                setFullIndicators(response.data);
                var Xlist = [];
                var Ylist = [];
                for (var i = 0; i < response.data.length; i++) {
                    Xlist.push(i);
                    Ylist.push(i);
                }
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        console.log("HELLO");
        fetchMyAPI();
    }, [fetchMyAPI]);

    useEffect(() => {
        let selectedIndicValues = [];
        let Xlist = [];
        fullIndicators.forEach((indicatorRow, index) => {
            if (index % Math.round(fullIndicators.length / 50) === 0) {
                //selectedIndicValues.push(indicatorRow["indicator"][selectedIndicator]);
                let value;

                if (selectedIndicator !== "Select")
                    value = indicatorRow["indicator"][selectedIndicator];
                else value = 0;
                speedKeys.includes(translate[selectedIndicator])
                    ? selectedIndicValues.push(
                        Math.round(Number(value) * 10 * factorSpeed) / 10
                    )
                    : distanceKeys.includes(translate[selectedIndicator])
                        ? selectedIndicValues.push(
                            Math.round(Number(value) * 10 * factorDistance) / 10
                        )
                        : selectedIndicValues.push(Math.round(Number(value) * 10) / 10);
            }
        });
        for (var i = 0; i <= fullIndicators.length; i++) {
            if (i % 100 === 0) Xlist.push(i);
        }
        console.log(Xlist.length);
        console.log(selectedIndicValues.length);
        setChart({
            options: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ["blue"],
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                            cssClass: "apexcharts-yaxis-label",
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ["blue", "blue", "blue"],
                    },
                },
                stroke: {
                    curve: "straight",
                },
                title: {
                    text: t(translate[selectedIndicator]),
                    align: "left",
                    style: {
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: undefined,
                        color: "var(--gold)",
                    },
                },
                grid: {
                    row: {
                        colors: ["gray", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                colors: ["var(--gold)", "transparent"],
                xaxis: {
                    range: 50,
                    labels: {
                        show: false,
                    },
                },
            },
            series: [
                {
                    name: "value",
                    data: selectedIndicValues,
                },
            ],
        });
    }, [selectedIndicator]);

    return isLoading ? (
        <Spin tip="Loading ..."></Spin>
    ) : (
        <>
            <div className="mainContainer">
                <select
                    className="selectValueDay"
                    value={t(selectedIndicator)}
                    onChange={handleSelectIndicator}
                >
                    {displayedIndicators.map((indicatorName) => (
                        <option className="optionDay" value={indicatorName}>
                            {t(translate[indicatorName])}
                        </option>
                    ))}
                </select>
            </div>
            { }
            <Chart
                options={chart.options}
                style={{
                    width: "auto",
                    height: "auto",
                    marginTop: "9%",
                    marginLeft: "10%",
                    textAlign: "-webkit-center",
                }}
                series={chart.series}
                type="bar"
                width={500}
                height={320}
            />
        </>
    );
};

export default withTranslation()(Graph);
