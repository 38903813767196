import * as React from "react";
import {
    Admin,
    Resource,
    defaultTheme,
    Layout,
    AppBar,
} from "react-admin";
import { createTheme, Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { UserList, UserEdit, UserCreate } from "../components/UserList";
import {
    TrackerList,
    TrackerEdit,
    TrackerCreate,
} from "../components/TrackerList";
import jsonServerProvider from "ra-data-json-server";
import authProvider from "../authProvider";

import merge from "lodash/merge";

const rawTheme = {
    palette: {
        primary: {
            main: "#C71585", // Not far from red
        },
        secondary: {
            main: "#000", // Not far from green
        },
    },
};

export const theme = createTheme(merge({}, defaultTheme, rawTheme));

const dataProvider = jsonServerProvider(
    `${process.env.REACT_APP_SERVER_URL}api/v1`
);

const useStyles = makeStyles({
    title: {
        color: "white",
    },
    menu: {
        backgroundColor: "#000",
    },
});

const MyAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} titleStyle={{ color: "white" }}>
            <Box flex="1">
                <Typography
                    variant="h6"
                    id="react-admin-title"
                    color="inherit"
                    className={classes.title}
                ></Typography>
            </Box>
        </AppBar>
    );
};

const MyLayout = (props) => (
    <Layout
        {...props}
        sx={{
            "& .RaLayout-content": { backgroundColor: "black" },
            "& .RaSidebar-fixed": { backgroundColor: "gray" },
            "& .RaLoginForm-content": { backgroundColor: "black" },
        }}
        appBar={MyAppBar}
    />
);

const AdminDash = () => (
    <Admin
        //loginPage={MyLoginPage}
        layout={MyLayout}
        theme={theme}
        basename="/admin"
        authProvider={authProvider}
        dataProvider={dataProvider}
    >
        <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
        />
        <Resource
            name="tracker"
            list={TrackerList}
            edit={TrackerEdit}
            create={TrackerCreate}
        />
    </Admin>
);

export default AdminDash;
