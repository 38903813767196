
const objectToCSVRow = (dataObject) => {
  console.log(dataObject)
  return dataObject
    .map(value => value === undefined ? "" : value)
    .map(value => value === null ? "" : value)
    .map(value => Number(value) === -1 ? "" : value)
    .map(value => "" + value)
    .map(value => value.replace(/"/g, ""))
    .join(";")
    + "\r\n";
};

export function exportToCSV(headers, lines, filename) {
  var csvContent = 
    "data:text/csv;charset=utf-8,"
    + objectToCSVRow(headers)
    + lines.map(values => objectToCSVRow(values)).join('\r\n');
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
}

